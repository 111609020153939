import { Content, Sidebar, Header } from './';
import { connect } from 'react-redux';
import { 
  setCurrentRole, 
  setCurrentStore, 
  setCurrenBarIndex, 
  setSideBarClick,
} from '../../store/actions/action';
import React from 'react';
import {
  withRouter
} from 'react-router-dom';
import API from '../../utils/API';
import UpgradeSupscription from '../UpgradeSupscription/UpgradeSupscription';
import PageSpinner from '../../components/PageSpinner';

const CONSTANT = require('../../utils/Const');

class MainLayout extends React.Component {
  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  static viewRole() {
    if (window.location.pathname.lastIndexOf("/front", 0) === 0) {
      return "front"
    } else if (window.location.pathname.lastIndexOf("/manager", 0) === 0) {
      return "manager"
    } else if (window.location.pathname.lastIndexOf("/customer", 0) === 0) {
      return "customer"
    }
  }

  componentWillReceiveProps({ breakpoint }) {
    if (breakpoint !== this.props.breakpoint) {
      this.checkBreakpoint(breakpoint);
    }
  }

  componentDidMount() {
    const {userState} = this.props;
    // this.openSidebar('close');
    // console.log('userState', this.props.userState)
    this.checkBreakpoint(this.props.breakpoint);
    // this.decideViewRole(window.location.pathname.toString())
  }

  // close sidebar when
  handleContentClick = event => {
    // close sidebar if sidebar is open and screen size is less than `md`
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === 'xs' ||
        this.props.breakpoint === 'sm' ||
        this.props.breakpoint === 'md')
    ) {
      this.openSidebar('close');
    }
  };

  checkBreakpoint(breakpoint) {
    switch (breakpoint) {
      case 'xs':
      case 'sm':
      case 'md':
        return this.openSidebar('close');

      case 'lg':
      case 'xl':
      default:
        return this.openSidebar('open');
    }
  }

  openSidebar(openOrClose) {
    if (!document.querySelector('.cr-sidebar')) {
      return;
    }
    
    if (openOrClose === 'open') {
      return document
        .querySelector('.cr-sidebar')
        .classList.add('cr-sidebar--open');
    }
    document.querySelector('.cr-sidebar').classList.remove('cr-sidebar--open');
  }

  changeRole(role) {
    let { currentStoreState } = this.props;
    const branchName = currentStoreState.currentStore.branchName;
    console.log('branchName', branchName);
    if (branchName.length > 0) {
      this.props.history.push("/" + branchName + "/" + role);
      this.props.setCurrentRole(role);
    }

  }

  changeStore = async (store) => {
    let data = this.props.currentStoreState;
    let allStore = this.props.storeState;

    // allStore = await (await API.getAllStore()).data;

    for (let i = 0; i < allStore.length; i++) {
      if (allStore[i]._id === store.id) {
        data.lastStore = data.currentStore;
        data.currentStore = allStore[i];
        data.index = store.index;
        break;
      }
    }

    this.props.setCurrentStore(data);

    const branchName = data.currentStore.branchName;
    const role = this.props.currentRoleState;
    localStorage.setItem(CONSTANT.key.STORE, JSON.stringify(data));
    this.props.history.push("/" + branchName + "/" + role);
    setTimeout(() => {
      this.props.setCurrenBarIndex(1);
      this.props.setCurrenBarIndex(0);
    }, 1000);
  }

  onPressUpgrade = () => {
    let data = this.props.currentStoreState;
    const branchName = data.currentStore.branchName;
    this.props.history.push({
      pathname: `/${branchName}/manager/setting`,
      state: {tabIndex: 4}
    });
    this.props.setCurrenBarIndex(10)
  }

  renderApp = (children) => {
    const {planActiveState, currentBarIndex, userState} = this.props;
    console.log('aaaaaaa', this.props)
    if (userState.id && planActiveState == -1) {
      return PageSpinner;
    }
    
    if (!userState.id || currentBarIndex == 10) {
      return children
    }

    if (userState.id && planActiveState) {
      return children
    } else {
      return <UpgradeSupscription onUpgrade = {this.onPressUpgrade}/>
    }
  }
  
  checkHideSideBar = () => {
    const {isSidebarClick, breakpoint} = this.props;
    const check = isSidebarClick && ['xs', 'sm','md'].includes(breakpoint) ||
      (
        window.location.pathname.indexOf("customer") >= 0 ||
        window.location.pathname.indexOf("staff/") >= 0 
      )
    return check
  }

  render() {
    const {children, breakpoint} = this.props;

    if (this.checkHideSideBar()) {
      this.openSidebar('close');
    } else {
      this.openSidebar('open');
    }

    return (
      <main className="cr-app bg-light" style={{ minWidth: 720}}>
        <Sidebar viewRole={MainLayout.viewRole()} />
        <Content fluid onClick={this.handleContentClick}>
          <Header
            onLogout={() => this.props.history.push("/login")}
            onChangeRole={(role) => this.changeRole(role)}
            onChangeStore={(data) => this.changeStore(data)}
            breakpoint ={breakpoint}
          />
         {this.renderApp(children)}
        </Content>
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.userData,
    currentRoleState: state.currentRole,
    storeState: state.allStoreList,
    currentStoreState: state.currentStore,
    currentBarIndex: state.currentBarIndex,
    isSidebarClick: state.isSidebarClick,
    planActiveState: state.planActiveState,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentRole: (data) => dispatch(setCurrentRole(data)),
    setCurrentStore: (data) => dispatch(setCurrentStore(data)),
    setCurrenBarIndex: (data) => dispatch(setCurrenBarIndex(data)),
    setSideBarClick: (data) => dispatch(setSideBarClick(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MainLayout));
