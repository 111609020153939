import React, {Component} from 'react';
import { connect } from 'react-redux';
import { setCurrenBarIndex } from '../store/actions/action';

export class RedirectPage extends Component {

    componentDidMount() {
        const {history, match} = this.props
        const branchName = match.params.branchName;
        const role = this.checkRolePathByUser(this.props.userState);

        if (branchName && role) {
            history.replace(`/${branchName}/${role}`);
            this.props.setCurrenBarIndex(0);
        } else {
            history.replace(`/login`);
        }
    }

    checkRolePathByUser(userData) {
        if (userData.manager_role) return "manager";
        if (userData.receptionist_role) return "front";
        if (userData.customer_role) return "customer";

        return ""
    }

    render() {
        return (
            <div></div>
        )
    }
}

function mapStateToProps(state) {
    return {
      userState: state.userData,
    }
  }
  
const mapDispatchToProps = dispatch => {
    return {
        setCurrenBarIndex: (data) => dispatch(setCurrenBarIndex(data)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(RedirectPage);