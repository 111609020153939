import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { setUserData } from '../store/actions/action';
import {
  withRouter
} from 'react-router-dom';
import API from '../utils/API';
import { Label } from '../components/Form';

const CONSTANT = require('../utils/Const');
var isRequest = false;
class PaymentSuccess extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showLogForm: false
    }
  }


  componentDidMount() {
    if (!isRequest) {
      isRequest = true;
      let checkout_id = this.findGetParameter("checkoutId");
      let reference_id = this.findGetParameter("referenceId");
      let transaction_id = this.findGetParameter("transactionId");
      this.checkout(checkout_id, reference_id, transaction_id);
    }

  }

  findGetParameter(parameterName) {
    var result = null,
      tmp = [];
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
  }

  checkout(checkout_id, reference_id, transaction_id) {
    let user = reference_id.split("_")[0];
    let body = {
      user,
      payment: {
        checkout_id,
        reference_id,
        transaction_id
      }
    }
    API.checkPaymentLog(body)
      .then(result => {
        if (result.status === 200) {
          if (result.data.status) {
            let userProfile = result.data.data;
            let userData = localStorage.getItem(CONSTANT.key.USER);
            if (userData && userData.length > 0) {
              localStorage.setItem(CONSTANT.key.USER, JSON.stringify(userProfile));
            }
            this.props.setUserData(userProfile);
            alert(result.data.message);
            this.props.history.push("/manager/setting");
          } else {
            alert(result.data.message);
            this.props.history.push("/manager/setting");
          }
        }
      })
      .catch(error => {
        console.log(error);
      })


  }

  render() {
    return (
      <Row
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          width: '99vw'
        }}>
        {
          this.state.showLogForm
            ? <Col md={6} lg={4}>
              <Card body>
                <Label>Your charge successfull.</Label>
              </Card>
            </Col>
            : null
        }

      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    userState: state.userData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => dispatch(setUserData(data)),

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PaymentSuccess));
