import PropTypes from 'prop-types';
import {checkScreenSmall} from '../../utils/Utils';
import {convertRoleName} from '../../utils/Utils';
// import SearchInput from 'components/SearchInput';
import { setUserData, fetchStorePage, setCurrenBarIndex, setCurrentStore, fetchInfoUser } from '../../store/actions/action';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  FaStore,
  FaUsers
} from 'react-icons/fa'

import React from 'react';
import {
  MdClearAll,
} from 'react-icons/md';
import {
  Button,
  Label,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle
} from 'reactstrap';
import bn from '../../utils/bemnames';

const bem = bn.create('header');

const CONSTANT = require('../../utils/Const');

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      manager_role: false,
      receptionist_role: false,
      customer_role: false,
      default_store: "",
      store_list: [],
      note: '',
      enable: false,
      lastUpdate: new Date()
    };
  }

  componentDidMount() {
    const { store_list, id } = this.props.userState;
    // this.props.fetchInfoUser(id)
    this.props.fetchStorePage(store_list);
    let userData = localStorage.getItem(CONSTANT.key.USER);
    let user = null;
    if (userData && userData.length > 0) {
      user = JSON.parse(userData);
    } else {
      user = this.props.userState;
    }

    if (user)
      this.setState({
        firstname: user.firstname,
        lastname: user.lastname,
        acc_owner: user.acc_owner,
        manager_role: user.manager_role,
        receptionist_role: user.receptionist_role,
        customer_role: user.customer_role,
        store_list: user.store_list,
        default_store: user.default_store,
        note: user.note,
        enable: user.enable,
        lastUpdate: user.lastUpdate
      })
  }

  getStoreName() {
    const allStore = this.props.storeState;
    // const myStore = this.props.storeOwnerState;
    // if (myStore && myStore.length > 0 && this.state.acc_owner) {
    //   return myStore[this.props.currentStoreState.index].storeName.toUpperCase();
    // } else if (AllStore && AllStore.length > 0) {
    //   return AllStore[this.props.currentStoreState.index].storeName.toUpperCase();
    // }
    if (allStore && allStore.length > 0) {
      if (allStore[this.props.currentStoreState.index]) {
        const storeName = allStore[this.props.currentStoreState.index].storeName
        return storeName ? storeName.toUpperCase() : 'STORE';
      } else {
        let data = this.props.currentStoreState;
        const role = this.props.currentRoleState;
        data.lastStore = data.currentStore;
        data.currentStore = allStore[0];
        data.index = 0;
        const defaultRoute = "/" + branchName + "/" + role;

        let router = localStorage.getItem(CONSTANT.key.ROUTE) || defaultRoute;        
        router.replace(data.lastStore.branchName, data.currentStore.branchName);

        localStorage.setItem(CONSTANT.key.ROUTE, router);
        localStorage.setItem(CONSTANT.key.STORE, JSON.stringify(data));

        const branchName = allStore[0].branchName;
        // this.props.history.push("/" + branchName + "/" + role);
        this.props.setCurrentStore(data);
        return allStore[0].storeName.toUpperCase();
      }

    }
    return "";
  }
  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    if (document.querySelector('.cr-sidebar')) {
      document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    }
    
  };

  handleChangeRole(role) {
    localStorage.setItem(CONSTANT.key.ROUTE, "/" + role);
    this.props.setCurrenBarIndex(0);
    this.props.onChangeRole(role)
  }

  render() {
    const { manager_role, acc_owner} = this.state;
    const {firstname, lastname} = this.props.userState;
    const { breakpoint, currentRoleState, storeState} = this.props;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Nav navbar>
          <Button outline onClick={this.handleSidebarControlButton} className="btn-sm">
            <MdClearAll size={"1em"} />
          </Button>
        </Nav>
        {/* <Nav navbar>
          <SearchInput />
        </Nav> */}
        <Nav navbar>
          {
            acc_owner ?
              <UncontrolledButtonDropdown className="ml-2 mr-2" >
                {/*Feedback: Tràn text store name khi tên store quá dài */}
                <DropdownToggle caret size="sm">
                   <FaStore className="button-icon" /> { checkScreenSmall(breakpoint) ? `${this.getStoreName().slice(0, 8).toUpperCase()}` : 'STORE' }
                </DropdownToggle>
                <DropdownMenu>
                  {storeState && storeState.map((store, index) => {
                    if (store.enabled)
                      return (
                        <DropdownItem
                          key={index}
                          onClick={() => {
                            this.props.onChangeStore({ index, id: store._id })
                          }}>
                          {store.storeName}
                        </DropdownItem>
                      )
                    return null
                  }
                  )}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              :
              <Label for={"Store"} className="mb-0"><span>{" "}</span><FaStore className="button-icon mr-2 ml-2 text-secondary" />{this.getStoreName()}</Label>
          }
          {
            acc_owner ? <Label for={"Store"} className="mb-0"><span>{" "}</span>{!checkScreenSmall(breakpoint) && this.getStoreName().toUpperCase()}</Label> : null
          }
        </Nav>
        <Nav navbar>
          {
            manager_role
              ? <UncontrolledButtonDropdown className="ml-2 mr-2">
                <DropdownToggle caret size="sm">
                  <FaUsers className="button-icon" /> { checkScreenSmall(breakpoint) ? `${currentRoleState ? currentRoleState.toUpperCase() : ""}` : 'ROLE' }
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => { this.handleChangeRole('customer') }}>Customer Check-in</DropdownItem>
                  <DropdownItem onClick={() => { this.handleChangeRole('front') }}>Receptionist</DropdownItem>
                  <DropdownItem onClick={() => { this.handleChangeRole('manager') }}>Manager</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              :
              null
          }
          {
            manager_role
              ? <Label for={"Role"} className="mb-0"><span>{" "}</span>{!checkScreenSmall(breakpoint) && (currentRoleState ? convertRoleName(currentRoleState) : "")}</Label>
              : null
          }
        </Nav>


        <Nav navbar className={bem.e('nav-right')}>
          <NavItem>
            <NavLink id="Popover2">
              <Label className="user-name">{firstname + " " + lastname}</Label>
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

Header.propTypes = {
  onLogout: PropTypes.func,
  onChangeRole: PropTypes.func,
  onChangeStore: PropTypes.func
};

Header.defaultProps = {
  onLogout: () => { },
  onChangeRole: () => { },
  onChangeStore: () => { }
};

const  mapStateToProps = state => {
  return {
    userState: state.userData,
    currentRoleState: state.currentRole,
    // storeState: state.allStoreList,
    storeState: state.userData.store_list,
    storeOwnerState: state.allStoreOwnerList,
    currentStoreState: state.currentStore,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => dispatch(setUserData(data)),
    fetchStorePage: (store_list) => dispatch(fetchStorePage(store_list)),
    setCurrenBarIndex: (data) => dispatch(setCurrenBarIndex(data)),
    setCurrentStore: (data) => dispatch(setCurrentStore(data)),
    fetchInfoUser: (data) => dispatch(fetchInfoUser(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
