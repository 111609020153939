import React from 'react';

const EmptyLayout = ({ children, ...restProps }) => (
  <div>{children}</div>
  // <main className="cr-app bg-light" {...restProps}>
  //   <Content fluid>{children}</Content>
  // </main>
);

export default EmptyLayout;
