import API from "../utils/API";
import { takeLatest, put } from 'redux-saga/effects';
import {caculateDistanceDate} from '../utils/Utils'
import { OrderReturnLineItem } from "square-connect";
import { data } from "jquery";

function* fetchCustomer(action) {
    const query = action.data;
    try {
        const customerList = yield API.getCustomerList({ store: query.store });
        console.log("From saga, customer:  ", customerList.data)
        yield put({ type: "FETCH_CUSTOMER_SUCCESS", customerdata: customerList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchCustomer() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_CUSTOMER', "FETCH_DATA_MANAGER_CUSTOMER"], fetchCustomer);
}

function* fetchEmp(action) {
    const query = action.data;
    try {
        const empList = yield API.getAvailableEmp(query);
        console.log("From saga, emp:  ", empList.data)
        yield put({ type: "FETCH_EMP_SUCCESS", empdata: empList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}

export function* watchfetchEmp() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_EMP', 'FETCH_CHECKIN_PAGE_FULL'], fetchEmp);
}

function* fetchAllEmp(action) {
    const query = action.data;
    try {
        const allEmpList = yield API.getAllEmp(query);
        console.log("From saga, all emp:  ", allEmpList.data)
        yield put({ type: "FETCH_ALL_EMP_SUCCESS", allEmpdata: allEmpList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllEmp() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_ALL_DATA_EMP', 'FETCH_DATA_MANAGER_EMP'], fetchAllEmp);
}



function* fetchService(action) {
    const query = action.data;
    try {
        const serviceList = yield API.getServiceList(query);
        console.log("From saga, service:  ", serviceList.data)
        yield put({ type: "FETCH_SERVICE_SUCCESS", servicedata: serviceList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchService() {
    yield takeLatest(['FETCH_DATA', 'FETCH_WAITLIST_PAGE_FULL', 'FETCH_DATA_SERVICE', 'FETCH_BOOKING', 'FETCH_CHECKIN_PAGE_FULL'], fetchService);
}

function* fetchEventLimit(action) {
    const query = action.data;
    try {
        const eventList = yield API.getEventLimit(query);
        console.log("From saga, event:", eventList.data)
        const eventList2 = eventList.data.map(obj => {
            obj.start = new Date(obj.start)
            obj.end = new Date(obj.end)
            return obj;
        })
        yield put({ type: "FETCH_EVENT_LIMIT_SUCCESS", eventdata: eventList2 });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchEventLimit() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_EVENT', 'FETCH_CHECKIN_PAGE_FULL', 'FETCH_CHECKIN_PAGE'], fetchEventLimit);
}


function* fetchAllService(action) {
    const query = action.data;
    try {
        const allServiceList = yield API.getAllService({ store: query.store });
        console.log("From saga, all service:  ", allServiceList.data)
        yield put({ type: "FETCH_ALL_SERVICE_SUCCESS", allServicedata: allServiceList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllService() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_ALL_DATA_SERVICE', 'FETCH_DATA_MANAGER_SERVICE'], fetchAllService);
}


function* fetchProduct(action) {
    const query = action.data;
    try {
        const productList = yield API.getAllProduct({ store: query.store });
        console.log("From saga, product:  ", productList.data)
        yield put({ type: "FETCH_PRODUCT_SUCCESS", productdata: productList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchProduct() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_PRODUCT', 'FETCH_CHECKIN_PAGE_FULL'], fetchProduct);
}



function* fetchCustReportSimple(action) {
    const query = action.data;
    try {
        const customerReportSimple = yield API.getCustomerReportSimple({ store: query.store });
        // const customerReportSimple = yield API.getCustomerReport({ store: query.store });
        console.log("from saga, customer report simple", customerReportSimple.data);
        yield put({ type: "FETCH_CUST_REPORT_SIMPLE_SUCCESS", customerReportSimpledata: customerReportSimple.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchCustReportSimple() {
    yield takeLatest(['FETCH_DATA', 'FETCH_CUST_REPORT_SIMPLE'], fetchCustReportSimple);
}

function* fetchCustReportForGroup(action) {
    const query = action.data;
    try {
        console.log("Reach Saga, action.groupId: ", query.groupId)
        const customerReportForGroup = yield API.getCustomerReportForGroup(query.groupId);
        yield put({ type: "FETCH_CUST_REPORT_FOR_GROUP_SUCCESS", customerReportForGroupdata: customerReportForGroup.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchCustReportForGroup() {
    yield takeLatest(['FETCH_CUST_REPORT_FOR_GROUP'], fetchCustReportForGroup);
}

// function* fetchCustReportForGroup(action) {
//     try{
//         console.log("Reach Saga, action.groupId: ", action.groupId)
//         const customerReportForGroup = yield API.getCustomerReportForGroup(action.groupId);
//         yield put({type: "FETCH_CUST_REPORT_FOR_GROUP_SUCCESS", customerReportForGroupdata: customerReportForGroup.data});
//     } catch (error){
//         yield put({type:"FETCH_FAILURE", error});
// }}
// export function* watchfetchCustReportForGroup() {
//     yield takeLatest(['FETCH_CUST_REPORT_FOR_GROUP'], fetchCustReportForGroup);
// }


function* fetchTax(action) {
    const query = action.data;

    try {
        const tax = yield API.getTax({ store: query.store });
        const taxData = tax.data;
        if (taxData.length > 0) {
            yield put({ type: "FETCH_TAX_SUCCESS", taxdata: tax.data[0] });
        }
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}

export function* watchfetchTax() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_TAX', 'FETCH_CHECKIN_PAGE_FULL', 'FETCH_DATA_MANAGER', 'FETCH_DATA_MANAGER_TAX'], fetchTax);
}


function* fetchAllStoreInfo(action) {
    const query = action.data;

    try {
        const store = yield API.getStoreDetails(query.store);
        yield put({ type: "FETCH_ALL_STORE_INFO_SUCCESS", fullStoreInfoData: store.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}

export function* watchfetchAllStoreInfo() {
    yield takeLatest(['FETCH_DATA','FETCH_DATA_MANAGER', 'FETCH_DATA_MANAGER_STORE', 'FETCH_FULL_STORE_INFO','FETCH_BOOKING'], fetchAllStoreInfo);
}


function* fetchAllProduct(action) {
    const query = action.data;
    try {
        const allProductList = yield API.getAllProduct({ store: query.store });
        console.log("From saga, all product:  ", allProductList.data)
        yield put({ type: "FETCH_ALL_PRODUCT_SUCCESS", allProductdata: allProductList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}

export function* watchfetchAllProduct() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_ALL_DATA_PRODUCT', 'FETCH_DATA_MANAGER_PRODUCT','FETCH_CUST_REPORT_SIMPLE','FETCH_CUST_REPORT_FOR_GROUP','FETCH_DATA_TRANSACTION_GROUP'], fetchAllProduct);
}

function* fetchAllPromotion(action) {
    const query = action.data;
    try {
        const allPromotionList = yield API.getAllPromotion({ store: query.store });
        console.log("From saga, all promotion:  ", allPromotionList.data)
        yield put({ type: "FETCH_ALL_PROMOTION_SUCCESS", allPromotiondata: allPromotionList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllPromotion() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_ALL_DATA_PROMOTION', 'FETCH_DATA_MANAGER_PROMOTION', 'FETCH_PROMOTION_PAGE'], fetchAllPromotion);
}

function* fetchAllDiscount(action) {
    const query = action.data;
    try {
        const allDiscountList = yield API.getAllDiscount({ store: query.store });
        console.log("From saga, all discount:  ", allDiscountList.data)
        yield put({ type: "FETCH_ALL_DISCOUNT_SUCCESS", allDiscountdata: allDiscountList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllDiscount() {
    yield takeLatest(['FETCH_PROMOTION_PAGE'], fetchAllDiscount);
}

function* fetchAllCustomerGroup(action) {
    const query = action.data;
    try {
        const customergroupList = yield API.getCustomerGroupList({ store: query.store });
        console.log("from sags: ", customergroupList)
        yield put({ type: "FETCH_CUSTOMERGROUP_SUCCESS", customergroupdata: customergroupList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllCustomerGroup() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_ALL_DATA_CUSTOMERGROUP', 'FETCH_DATA_MANAGER_CUSTOMERGROUP', 'FETCH_PROMOTION_PAGE', "FETCH_CUST_REPORT_SIMPLE"], fetchAllCustomerGroup);
}

function* fetchAllCustomerGrouping(action) {
    const query = action.data;
    try {
        const customergroupingList = yield API.getCustomerGroupingList({ store: query.store });
        console.log("From saga, all customer grouping:  ", customergroupingList.data)
        yield put({ type: "FETCH_CUSTOMERGROUPING_SUCCESS", customergroupingdata: customergroupingList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllCustomerGrouping() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_ALL_DATA_CUSTOMERGROUPING', 'FETCH_DATA_MANAGER_CUSTOMERGROUPING'], fetchAllCustomerGrouping);
}

function* fetchWaitlist(action) {
    const query = action.data;
    try {
        const waitlist = yield API.getWaitlist(query);
        console.log("From saga, waitlist:  ", waitlist.data)
        yield put({ type: "FETCH_WAITLIST_SUCCESS", waitlistdata: waitlist.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchVisit() {
    yield takeLatest(['FETCH_WAITLIST_PAGE_FULL', 'FETCH_WAITLIST_PAGE', 'FETCH_DATA_WAITLIST'], fetchWaitlist);
}



function* fetchTransactionLimitTime(action) {
    try {
        let query = { tranIn: action.data.tranIn, tranOut: action.data.tranOut, store: action.data.store }
        const transactionList = yield API.getTranListLimitTime(query);
        console.log("Saga tran limit time data", transactionList.data)
        yield put({ type: "FETCH_TRAN_LIMIT_TIME_SUCCESS", tranListdata: transactionList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchTransactionLimitTime() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_TRANSACTION_LIMIT_TIME', 'FETCH_DATA_MANAGER_TRANSACTION'], fetchTransactionLimitTime);
};

function* fetchVisitLimitTime(action) {
    let query = { visitIn: action.data.visitIn, visitOut: action.data.visitOut, store: action.data.store }
    try {
        const visitList = yield API.getVisitListLimitTime(query);
        console.log("Saga data, visit limited time", visitList.data)
        yield put({ type: "FETCH_VISIT_LIMIT_TIME_SUCCESS", visitListdata: visitList.data });
    } catch (error) {

        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchVisitLimitTime() {
    yield takeLatest(['FETCH_DATA_MANAGER', 'FETCH_VISIT_LIMIT_TIME', 'FETCH_DATA_MANAGER_VISIT'], fetchVisitLimitTime);
};

function* fetchTransaction() {
    try {
        const transactionList = yield API.getTransactionList();
        console.log("From saga, transaction:  ", transactionList.data)
        yield put({ type: "FETCH_TRANSACTION_SUCCESS", transactiondata: transactionList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchTransaction() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_TRANSACTION'], fetchTransaction);
};

function* fetchTransactiongGroup(action) {
    const query = action.data;
    try {
        let transactionGroup = {};
        // const transactionGroupActive = yield API.getTransactionGroupActiveTest({ store: query.store });
        // const transactionGroupCompleted = yield API.getTransactionGroupCompletedTest({ store: query.store });
        const transactionGroupActive = yield API.getTransactionGroupActiveToday({ store: query.store });
        const transactionGroupCompleted = yield API.getTransactionGroupCompletedToday({ store: query.store });
        // const transactionListCompleted = yield API.getTransactionGroupCompleted({ store: query.store });

        transactionGroup.active = transactionGroupActive.data; //.sorting((a, b) => { new Date(a.waitlist_time) - new Date(b.waitlist_time) });
        transactionGroup.completed = transactionGroupCompleted.data
        // transactionGroup.list = transactionListCompleted.data
        // console.log('transactionGroup', transactionGroup);
        //  const transactionGroup = yield API.getTransactionGroupActive();
        console.log("From saga, transaction group:  ", transactionGroup)
        yield put({ type: "FETCH_TRANSACTION_GROUP_SUCCESS", transactiongroupdata: transactionGroup });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchTransactionGroup() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_MINOR', 'FETCH_DATA_TRANSACTION_GROUP', 'FETCH_DATA_TRANSACTION', 'FETCH_CHECKIN_PAGE_FULL', 'FETCH_CHECKIN_PAGE'], fetchTransactiongGroup);
};

function* fetchDailyEmpReport(action) {
    const query = action.data;
    try {
        const dailyEmpReport = yield API.getDailyEmpReport(query);
        console.log("From saga, dailyemreport group:  ", dailyEmpReport.data)
        yield put({ type: "FETCH_DAILY_REPORT_EMP_SUCCESS", dailyEmpReportdata: dailyEmpReport.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchDailyEmpReport() {
    yield takeLatest(['FETCH_DAILY_REPORT_EMP', 'FETCH_DAILY_REPORT'], fetchDailyEmpReport);
};

function* fetchSevenFourData(action) {
    let query = action.data;
    try {
        const sevenFourData = yield API.getSevenFour(query);
        yield put({ type: "FETCH_SEVEN_FOUR_REPORT_SUCCESS", sevenFourDatadata: sevenFourData.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchSevenFourData() {
    yield takeLatest(['FETCH_SEVEN_FOUR', 'FETCH_DAILY_REPORT'], fetchSevenFourData);
};

function* fetchManagerStoreData(action) {
    let query = action.data;
    try {
        const managerStoreReport = yield API.getManagerStoreReport(query);
        yield put({ type: "FETCH_MANAGER_STORE_REPORT_SUCCESS", managerStoreReportdata: managerStoreReport.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};

export function* watchfetchManagerStoreData() {
    yield takeLatest(['FETCH_MANAGER_STORE_REPORT'], fetchManagerStoreData);
};

function* fetchDailyStoreReport(action) {
    let query = action.data;
    try {
        const dailyStoreReport = yield API.getDailyStoreReport(query);
        yield put({ type: "FETCH_DAILY_REPORT_STORE_SUCCESS", dailyStoreReportdata: dailyStoreReport.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchDailyStoreReport() {
    yield takeLatest(['FETCH_DAILY_REPORT_STORE', 'FETCH_DAILY_REPORT'], fetchDailyStoreReport);
};

function* fetchEmpGroup(action) {
    let query = action.data;
    try {
        const empGroup = yield API.getEmpGroup(query);
        console.log("From saga, emp group:  ", empGroup.data)
        yield put({ type: "FETCH_EMP_GROUP_SUCCESS", empgroupdata: empGroup.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchEmpGroup() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_MINOR', 'FETCH_EMP_TRANSACTION_GROUP', 'FETCH_CHECKIN_PAGE_FULL', 'FETCH_CHECKIN_PAGE'], fetchEmpGroup);
};

function* fetchEmpGroupId(action) {
    let query = action.data;
    try {
        const empGroupId = yield API.getEmpGroupId(query);
        console.log("From saga, emp group Id:  ", empGroupId.data[0].empWorked)
        yield put({ type: "FETCH_EMP_GROUP_ID_SUCCESS", empgroupiddata: empGroupId.data[0].empWorked });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
};
export function* watchfetchEmpGroupId() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_MINOR', 'FETCH_EMP_TRANSACTION_GROUP_ID', 'FETCH_CHECKIN_PAGE_FULL', 'FETCH_CHECKIN_PAGE'], fetchEmpGroupId);
};

function* fetchAllStore(action) {
    // const store_list = action.store_list;
    try {
        const allStoreList = yield API.getAllStore();
        console.log("From saga, all store:  ", allStoreList.data);
        yield put({ type: "SET_STORE_DATA", data: allStoreList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}

export function* watchfetchAllStore() {
    yield takeLatest(['FETCH_DATA', 'FETCH_STORE_PAGE'], fetchAllStore);
}

function* fetchAllReceptionist(action) {
    const query = action.data;

    try {
        const allReceptionistList = yield API.getAllReceptionist(query);
        console.log("receptionist_list", allReceptionistList.data);
        yield put({ type: "SET_RECEPTIONIST_DATA", data: allReceptionistList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}

export function* watchfetchAllReception() {
    yield takeLatest(['FETCH_DATA', 'FETCH_RECEPTIONIST_PAGE'], fetchAllReceptionist);
}


function* fetchAllSms(action) {
    const query = action.data;
    let params = query.category.length > 0 ? query : { store: query.store }

    try {
        const smsReportList = yield API.getSmsReport(params);
        console.log("From saga, sms report:  ", smsReportList.data)
        yield put({ type: "FETCH_SMS_REPORT_SUCCESS", smsReportList: smsReportList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchSmsReport() {
    yield takeLatest(['FETCH_DATA', 'FETCH_DATA_SMS_REPORT'], fetchAllSms);
}

function* fetchAllCustomer(action) {
    const query = action.data;
    try {
        const customerList = yield API.getCustomerList(query);
        console.log("From saga, all customer:  ", customerList.data)
        yield put({ type: "FETCH_ALL_CUSTOMER_SUCCESS", allCustomerData: customerList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllCustomer() {
    yield takeLatest(['FETCH_DATA', 'FETCH_ALL_DATA_CUSTOMER'], fetchAllCustomer);
}



function* fetchAllAppointment(action) {
    const query = action.data;
    try {
        const appointmentList = yield API.getAppointmentlist(query);
        console.log("From saga, all appointment:  ", appointmentList.data)
        yield put({ type: "FETCH_ALL_APPOINTMENT_SUCCESS", allAppointmentData: appointmentList.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}
export function* watchfetchAllAppointment() {
    yield takeLatest(['FETCH_DATA', 'FETCH_ALL_APPOINTMENT'], fetchAllAppointment);
}

function* fetchInfoUser(action) {
    const id = action.data;
    try {
        const res = yield API.getUserById(id);
        yield put({ type: "SET_USER_DATA", data: res.data.data });
    } catch (error) {
        yield put({ type: "FETCH_FAILURE", error });
    }
}

export function* getUserById() {
    yield takeLatest(['GET_USER'], fetchInfoUser);
}


export function* watchFetchStoreExpired() {
    yield takeLatest(['GET_STORE_TRIAL_EXPIRED'], fetchStoreExpired);
}

function* fetchStoreExpired(action) {
    // GET_STORE_TRIAL_EXPIRED
    const {data} = action;
    try {
        const res = yield API.getTrialExpiryDate(data);
        const dataResponse = res.data;

        if (dataResponse.status == 1) {
            yield put({type: "GET_STORE_TRIAL_EXPIRED_SUCCESS", data: dataResponse.data})
        } else {
            yield put({type: "GET_STORE_TRIAL_EXPIRED_FAIL"})
        }
        
    } catch(error) {
        yield put({type: "GET_STORE_TRIAL_EXPIRED_FAIL"})
    }
    
}

export function* watchFetchCurrentSubcription() {
    yield takeLatest(['GET_CURRENT_SUBCRIPTION'], fetchCurrentSubcription);
}

function* fetchCurrentSubcription(action) {
    const {data} = action;
    const {userId} = data;

    try {
        const res = yield API.getCurrentPlan(userId);
        const dataResponse = res.data;
        if (dataResponse.status == 1) {
            yield put({type: "GET_CURRENT_SUBCRIPTION_SUCCESS", data: dataResponse.data})
        } else {
            yield put({type: "GET_CURRENT_SUBCRIPTION_FAIL"})
        }
    } catch(error) {
        yield put({type: "GET_CURRENT_SUBCRIPTION_FAIL"})
        console.log(error);
    }
}


function * fetchDataPlan(action) {
    const {data} = action;
    const {id} = data;
    
    try {
        const planDataRes = yield API.getCurrentPlan(id)
        const planData = planDataRes.data;
        console.log(planDataRes);

        if (planData.status == 1) {
            // check
            const currentPlan = planData.data;
            const recurringDate = new Date(currentPlan.recurring_expried_date);
            const now = new Date();

            if (recurringDate >= now) {
                yield put({type: "PLAN_ACTIVE_STATUS_OK", data: {type: 'plan'}})
                return;
            } else {
                yield put({type: "PLAN_ACTIVE_STATUS_FINISH"})
                return;
            }
        }

        const trialDataRes = yield API.getTrialExpiryDate(data)
        console.log(trialDataRes);
        const trialData = trialDataRes.data.data;

        const nowDate = Math.floor(Date.now() / 1000);
        const expiredDate = trialData.expired_date;
        const remainDate = caculateDistanceDate(nowDate, expiredDate) + 1 

        if (remainDate >= 0) {
            yield put({type: "PLAN_ACTIVE_STATUS_OK", data: {type: 'trial'}})
            return;
        }

        // defaukt
        yield put({type: "PLAN_ACTIVE_STATUS_FINISH"})


    } catch(error) {
        yield put({type: "PLAN_ACTIVE_STATUS_FINISH"})
        console.log(error);
    }
}

export function* watchFetchPlanState() {
    yield takeLatest(['PLAN_ACTIVE_STATUS'], fetchDataPlan);
}

function* fetchHelpContent() {
    try {
        const res = yield API.getAllHelpContent();
        const dataResponse = res.data;
        if (dataResponse.status == 1) {
           
            yield put({type: "FETCH_ALL_CONTENT_OK", data: dataResponse.data})
        }
    } catch(error) {
        console.log(error)
    }
}

export function* watchFetchHelpContent() {
    yield takeLatest(['FETCH_ALL_CONTENT'], fetchHelpContent)
}

function* fetchDefaultLanguage(action) {
    const {data} = action;
    try {
        const res = yield API.getUserDefaultLanguage(data.id)
        const dataResponse = res.data;
        if (dataResponse.status == 1) {
            yield put({type: "SET_DEFAULT_LANGUAGE", data: dataResponse.data.default_lang})
        }
    } catch(error) {
        console.log(error)
    }
}

export function* watchFetchDefaultLanguage() {
    yield takeLatest(['FETCH_DEFAULT_LANGUAGE'], fetchDefaultLanguage)
}

function* fetchPendingAppointmment(action) {
    const {data} = action;
    try {
        const res = yield API.getPendingAppointment(data);
        const dataResponse = res.data;
        console.log(dataResponse)
        if (dataResponse.status == 1) {
            yield put({type: "SET_PEDDING_APPOINTMENT_LIST", data: dataResponse.data})
        }
    } catch(error) {
        console.log(error)
    }
}

export function* watchFetchPendingAppointmment() {
    yield takeLatest(['FETCH_PENDING_APPOINTMENT'], fetchPendingAppointmment)
}

function *fetchGarellyImages(action) {
    const {data} = action;
    try {
        const res = yield API.getImageGarelly(data);
        const dataResponse = res.data;
        console.log("from api", dataResponse)
        if (dataResponse.status == 1) {
            yield put({type: "FETCH_GARELLY_IMAGE_SUCCESS", data: dataResponse.data})
        }
    } catch(error) {
        console.log(error)
    }
}
export function* watchFetchGarellyImages() {
    yield takeLatest(['FETCH_GARELLY_IMAGE'], fetchGarellyImages)
}