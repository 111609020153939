const initiateState = {
    waitlist: [],
    empEvents: [],
    eventList: [],
    customerReportSimple: [],
    customerReportForGroup: [],
    tax: {percProduct: 0, percService: 0},
    sevenFourData: {},
    productList: [],
    allProductList: [],
    dailyEmpReport: [],
    dailyStoreReport: [],
    managerStoreReport: [],
    allPromotionList: [],
    allDiscountList:[],    
    transactionList: [],
    // available employee    
    empList: [],
    //convert empList into object
    empListData: {},
    allEmpList: [],
    allVisitList: [],
    allTranList: [],
    allServiceList: [],
    allCustomerGroup: [],
    allCustomerGrouping: [],

    // still need to add not started emp
    notStartedEmpList: [],
    // aggregate from transaction
    empGroup: [],
    // get Id for not started EmpList
    empGroupId: [],
    serviceList: [],
    customerList: [],
    transactionGroupActive: [],
    // transactionGroupCompleted: [],
    // transactionGroupList: [],
    userData: {
        "id": "",
        "username": "",
        "photo": "noimage",
        "manager_role": false,
        "receptionist_role": false,
        "customer_role": false,
        "default_store": "", 
        "note": "",
        "enabled": false
    },
    currentRole: 'customer',
    allStoreList: [],
    allStoreOwnerList: [],
    currentStore: { index: 0, currentStore: "", lastStore: '' },
    fullStoreInfo: {},
    allReceptionistList: [],
    smsReportList: [],
    allCustomerList: [],
    allAppointmentList: [],
    currentBarIndex: 0,
    recipientList: [],
    isSidebarClick: false,
    trialData: {},
    currentSubcription: {},
    defaultLanguage: '',
    allHelpContent: [],
    visibilityContent: [],
    isOpenHelperModal: false,
    openHelperKey: '',
    pendingAppointmentList: [],
    garellyImages: [],
    planActiveState: -1
}

const reducer = (state = initiateState, action) => {
    const newState = { ...state }
    switch (action.type) {
        case "FETCH_CUSTOMER_SUCCESS":
            newState.customerList = action.customerdata;
            break;
        case "FETCH_TAX_SUCCESS":
            newState.tax = action.taxdata;
            break;
        case "FETCH_CUST_REPORT_FOR_GROUP_SUCCESS":
            newState.customerReportForGroup = action.customerReportForGroupdata;
            break;
        case "FETCH_CUST_REPORT_SIMPLE_SUCCESS":
            newState.customerReportSimple = action.customerReportSimpledata;
            break;
        case "UPDATE_EMP":
            console.log('from actionn ', action.newEmp)
            newState.empList = action.newEmp;
            break;
        case "FETCH_EMP_SUCCESS":
            newState.empList = action.empdata;
            action.empdata.forEach(element => {
                newState.empListData[element._id] = element.emp_name;
            });
            newState.notStartedEmpList = action.empdata.filter((obj) => {
                return state.empGroupId.indexOf(obj._id) === (-1)
            })
            break;
        case "FETCH_ALL_EMP_SUCCESS":
            newState.allEmpList = action.allEmpdata;
            break;
        case "FETCH_ALL_PROMOTION_SUCCESS":
            newState.allPromotionList = action.allPromotiondata;
            break;
        case "FETCH_ALL_DISCOUNT_SUCCESS":
            newState.allDiscountList = action.allDiscountdata;
            break;            
        case "FETCH_VISIT_LIMIT_TIME_SUCCESS":
            newState.allVisitList = action.visitListdata;
            break;
        case "FETCH_TRAN_LIMIT_TIME_SUCCESS":
            newState.allTranList = action.tranListdata;
            break;
        case "FETCH_ALL_SERVICE_SUCCESS":
            newState.allServiceList = action.allServicedata;
            break;
        case "FETCH_EVENT_LIMIT_SUCCESS":
            newState.eventList = action.eventdata;
            break;
        case "FETCH_SERVICE_SUCCESS":
            newState.serviceList = action.servicedata;
            break;
        case "FETCH_ALL_PRODUCT_SUCCESS":
            newState.allProductList = action.allProductdata;
            break;
        case "FETCH_DAILY_REPORT_EMP_SUCCESS":
            newState.dailyEmpReport = action.dailyEmpReportdata;
            break;
        case "FETCH_SEVEN_FOUR_REPORT_SUCCESS":
            newState.sevenFourData = action.sevenFourDatadata;
            break;
        case "FETCH_DAILY_REPORT_STORE_SUCCESS":
            newState.dailyStoreReport = action.dailyStoreReportdata;
            break;
        case "FETCH_MANAGER_STORE_REPORT_SUCCESS":
            newState.managerStoreReport = action.managerStoreReportdata;
            break;
        case "FETCH_ALL_STORE_INFO_SUCCESS":
            newState.fullStoreInfo = action.fullStoreInfoData;
            break;
        case "FETCH_PRODUCT_SUCCESS":
            newState.productList = action.productdata;
            break;
        case "FETCH_CUSTOMERGROUP_SUCCESS":
            newState.allCustomerGroup = action.customergroupdata;
            break;
        case "FETCH_CUSTOMERGROUPING_SUCCESS":
            newState.allCustomerGrouping = action.customergroupingdata;
            break;
        case "FETCH_WAITLIST_SUCCESS":
            newState.waitlist = action.waitlistdata;
            break;
        case "FETCH_TRANSACTION_SUCCESS":
            newState.transactionList = action.transactiondata;
            break;
        case "FETCH_TRANSACTION_GROUP_SUCCESS":
            newState.transactionGroupActive = action.transactiongroupdata.active;
            newState.transactionGroupCompleted = action.transactiongroupdata.completed;
            // newState.transactionGroupList = action.transactiongroupdata.list;
            break;
        case "FETCH_EMP_GROUP_SUCCESS":
            newState.empGroup = action.empgroupdata;
            newState.empEvents = action.empgroupdata.map(object => {
                let result = [];
                let startTimeFinal = new Date(object.start_time_final);
                object.transactionList.forEach(obj => {
                    if (obj.status === "finished") {
                        result.push({
                            id: obj.tranId,
                            title: obj.service_name,
                            start: new Date(obj.start_time),
                            customerId: obj.customerId,
                            tranId: obj.tranId,
                            visitId: obj.visitId,
                            type: "automatic",
                            status: obj.status,
                            end: new Date(obj.end_time),
                            resourceId: object._id
                        })
                    } else {
                        result.push({
                            id: obj.tranId,
                            title: obj.service_name,
                            start: startTimeFinal,
                            customerId: obj.customerId,
                            tranId: obj.tranId,
                            visitId: obj.visitId,
                            type: "automatic",
                            status: obj.status,
                            end: new Date(startTimeFinal.getTime() + parseInt(obj.duration_min) * 60000),
                            resourceId: object._id
                        })
                        startTimeFinal = new Date(startTimeFinal.getTime() + (parseInt(obj.duration_min) + 5) * 60000);
                    }

                }
                );
                return (result)
            }).flat();
            break;
        case "FETCH_EMP_GROUP_ID_SUCCESS":
            newState.empGroupId = action.empgroupiddata;
            newState.notStartedEmpList = state.empList.filter((obj) => {
                return action.empgroupiddata.indexOf(obj._id) === (-1)
            })
            break;
        case "FETCH_FAILURE":
            return state;
        case "SET_USER_DATA":
            newState.userData = Object.assign({}, action.data);
            break;
        case "CLEAR_USER_DATA":
            newState.userData = { "id": "", "username": "", "photo": "noimage", "store_list": [], "manager_role": false, "receptionist_role": false, "customer_role": false, "default_store": "", "note": "", "enabled": false, "account": '' };
            break;
        case "SET_CURRENT_ROLE":
            newState.currentRole = action.data;
            break;
        case "SET_STORE_DATA":
            newState.allStoreList = action.data;
            break;
        case "SET_STORE_OWNER_DATA":
            newState.allStoreOwnerList = action.data;
            break;

        case "SET_CURRENT_STORE":
            newState.currentStore = action.data;
            break;
        case "SET_RECEPTIONIST_DATA":
            newState.allReceptionistList = action.data;
            break;
        case "FETCH_SMS_REPORT_SUCCESS":
            newState.smsReportList = action.smsReportList;
            break;
        case "FETCH_ALL_CUSTOMER_SUCCESS":
            newState.allCustomerList = action.allCustomerData;
            break;
        case "FETCH_ALL_APPOINTMENT_SUCCESS":
            newState.allAppointmentList = action.allAppointmentData;
            break;
        case "SET_CURRENT_BAR_INDEX":
            newState.currentBarIndex = action.data;
            break;
        case "SET_RECIPIENT_LIST":
            newState.recipientList = action.data;
            break;
        case "SET_SIDEBAR_CLICK":
            newState.isSidebarClick = action.data;
            break;
        case "GET_STORE_TRIAL_EXPIRED_SUCCESS": 
            newState.trialData = action.data;
            break;
        case "GET_STORE_TRIAL_EXPIRED_FAIL":
            newState.trialData = {};
            break;
        case "GET_CURRENT_SUBCRIPTION_SUCCESS":  
            newState.currentSubcription = action.data;
            break;
        case "GET_CURRENT_SUBCRIPTION_FAIL":
            newState.currentSubcription = {};
            break;
        case "PLAN_ACTIVE_STATUS_OK": 
            newState.planActiveState = action.data;
            break;
        case "PLAN_ACTIVE_STATUS_FINISH": 
            newState.planActiveState = false;
            break;
        case "SET_DEFAULT_LANGUAGE": 
            newState.defaultLanguage = action.data;
            newState.visibilityContent = state.allHelpContent.filter(content => content.language == action.data)
            break;
        case "FETCH_ALL_CONTENT_OK":
            newState.allHelpContent = action.data;
            newState.visibilityContent = newState.allHelpContent.filter(content => content.language == state.defaultLanguage)
            break;
        case "TOGGLE_HELPER_MODAL": 
            newState.isOpenHelperModal = !state.isOpenHelperModal;
            newState.openHelperKey = action.data;
            break;
        case "SET_PEDDING_APPOINTMENT_LIST":
            newState.pendingAppointmentList = action.data;
            break;
        case "FETCH_GARELLY_IMAGE_SUCCESS":
            newState.garellyImages = action.data;
            break;
        default:
            return state;
    };

    return newState;

}

export default reducer;
