import React from 'react';

function parseIcon(iconName, color) {
    return `<i class="fa fa-${iconName} text-${color}" aria-hidden="true"></i>`
}
const addStaffModal = {
    eng: `
        <div>
            <p>
            <b>Name: </b>Technician's name. A name will appear in multiple place (online booking, dashboard), so make sure it's short and concise
            <br>
            <b>Code: </b>Secret code. Technicians can use this code to log in their interface
            <br>
            <b>Photo: </b>A small minimal avatar to differentiate between technicians
            <br>
            <b>% Owner: </b>How many percent of the service price is the owner's share. E.g, 40 means the store keeps 40% of the income, and the technician keeps 60%.
            <br>
            <b>% Back Tip Card: </b>How many percent of the customer's tip given through card payment is the owner's share
            <br>
            <b>% Back Tip Cash: </b>How many percent of the customer's tip given through cash payment is the owner's share
            <br>
            <b>Enabled: </b>If a technician is disabled, his/her information is hidden everywhere. This is useful when a technician is temporarily off
            <br>
            <b>Show on Appt List: </b>If this is set "true" or "enabled" customers will see the technician on the online booking page
            </p>
        </div>
    `,
    vi: `
        <div>
            <p>
            <b>Name: </b>Tên nhân viên. Tên nên được dùng ngắn gọn để tiện cho việc hiển thị vì tên nhân viên xuất hiện rất nhiều nơi (online booking, dashboard)
            <br>
            <b>Code: </b>Mã nhân viên. Mã này cần được giữ bí mật cho mỗi nhân viên vì nhân viên có thể log in vào ứng dụng bằng mã của mình
            <br>
            <b>Photo: </b>Ảnh đại diện
            <br>
            <b>% Owner: </b>Phần trăm của tiệm khi tính thu nhập. Ví dụ nếu điền 40, thì 40% thu nhập từ dịch vụ thuộc về tiệm và 60% thu nhập thuộc về nhân viên
            <br>
            <b>% Back Tip Card: </b>% tiền tip bằng card tiệm giữ lại
            <br>
            <b>% Back Tip Cash: </b>% tiền tip bằng cash tiệm giữ lại
            <br>
            <b>Enabled: </b>Nếu là "false" tức disabled, thì thông tin nhân viên sẽ được ẩn đi hoàn toàn. Điều này hữu dụng khi nhân viên tạm nghỉ
            <br>
            <b>Show on Appt List: </b>Nếu là "true" hoặc "enabled" thì thông tin nhân viên sẽ xuất hiện trên trang online booking
            </p>
        </div>
    `,
    span: `
        <div>
            <p>
            <b>Name: </b> nombre del técnico. Aparecerá un nombre en varios lugares (reserva en línea, panel de control), así que asegúrese de que sea breve y conciso
            <br>
            <b>Code: </b> código secreto. Los técnicos pueden usar este código para iniciar sesión en su interfaz
            <br>
            <b>Photo: </b> un pequeño avatar mínimo para diferenciar a los técnicos
            <br>
            <b>% Owner: </b> ¿Cuál es el porcentaje del precio del servicio que corresponde al propietario? Por ejemplo, 40 significa que la tienda se queda con el 40% de los ingresos y el técnico con el 60%.
            <br>
            <b>% Back Tip Card: </b> ¿Cuál es el porcentaje de la propina que el cliente da a través del pago con tarjeta es la parte del propietario?
            <br>
            <b>%% Back Tip Cash: </b> ¿Qué porcentaje de la propina del cliente entregada a través del pago en efectivo es la parte del propietario?
            <br>
            <b> Enabled: </b> Si un técnico está inhabilitado, su información está oculta en todas partes. Esto es útil cuando un técnico está temporalmente ausente.
            <br>
            <b>Show on Appt List: </b> si se establece como "verdadero" o "habilitado", los clientes verán al técnico en la página de reserva en línea.
            </p>
        </div>
    `
};

const addServiceModal = {
    eng: `
        <div>
            <p>
                <b>Category: </b>Groups of service. This helps organize services in group when being displayed
                <br>
                <b>Service: </b>Name of the service
                <br>
                <b>Price: </b>The price of the service
                <br>
                <b>Turn Count: </b>Turn count allocated for this service for visit without appointment
                <br>
                <b>Turn Count w/ Appt: </b>Turn count allocated for this service for visit with appointment
                <br>
                <b>Duration: </b>Estimated time (in minutes) required to complete this service. This info is used to calculate estimated wait time during working hour or on online booking page
                <br>
                <b>Store Expense: </b>The amount store keeps to account for store's expense associated with a service. This amount is deducted from the service price before allocating income to the store and technicians
                <br>
                <b>Enabled: </b>If this is set "false" or "disabled", this service is hidden
            </p>
        </div>
    `,
    vi: `
        <div>
            <p>
                <b>Category: </b>Loại dịch vụ. Các dịch vụ được nhóm theo loại khi hiển thị để thuận tiện cho việc chọn lựa
                <br>
                <b>Service: </b>Tên dịch vụ
                <br>
                <b>Price: </b>Giá dịch vụ
                <br>
                <b>Turn Count: </b>Số turn tương ứng với dịch vụ trong trường hợp khách hàng walk-in (ko có appointment)
                <br>
                <b>Turn Count w/ Appt: </b>Số turn tương ứng với dịch vụ khi khách hàng có đặt lịch trước. Nếu cửa hàng ko có chính sách phân biệt giữa khách walk-in và khách có đặt lịch thì để số turn ở cột này và cột trước bằng nhau.
                <br>
                <b>Duration: </b>Thời gian ước tính (tính bằng phút) cần thiết để hoàn thành dịch vụ này. Thông tin này được sử dụng để tính thời gian chờ hoặc hiện trên trang online-booking
                <br>
                <b>Store Expense: </b>Số tiền cửa hàng giữ lại (ví dụ tiền supply). Số tiền này được trừ ra trước khi chia thu nhập giữa cửa hàng và kỹ thuật viên
                <br>
                <b>Enabled: </b>Nếu là "false" tức disabled thì dịch vụ này sẽ bị ẩn đi hoàn toàn
            </p>
        </div>
    `,
    span: `
        <div>
            <p>
                <b>Category: </b>Grupos de servicio. Esto ayuda a organizar los servicios en grupo cuando se muestran
                <br>
                <b>Service: </b>Nombre del servicio
                <br>
                <b>Price: </b>El precio del servicio
                <br>
                <b>Turn Count: </b>Número de turnos asignado a este servicio para visitas sin cita previa
                <br>
                <b>Turn Count w/ Appt: </b>Número de turnos asignado para este servicio para visitas con cita
                <br>
                <b>Duration: </b>Tiempo estimado (en minutos) necesario para completar este servicio. Esta información se utiliza para calcular el tiempo de espera estimado durante el horario laboral o en la página de reserva en línea
                <br>
                <b>Store Expense: </b>La cantidad que la tienda mantiene para contabilizar los gastos de la tienda asociados con un servicio. Esta cantidad se deduce del precio del servicio antes de asignar los ingresos a la tienda y los técnicos
                <br>
                <b>Enabled: </b>Si se establece como "falso" o "inhabilitado", este servicio está oculto
            </p>
        </div>
    `
};

const taxSetting = {
    eng: `
        <div>
            <p>
                <b>Service Tax: </b>Tax applied on the total service price of a visit. This amount is added to the bill and paid by the customers
                <br>
                <b>Product Tax: </b>Tax applied on the total product price of a visit. This amount is added to the bill and paid by the customers
            </p>
        </div>
    `,
    vi: `
        <div>
            <p>
                <b>Service Tax: </b>Thuế được áp dụng trên tổng giá dịch vụ. Số tiền này được cộng vào hóa đơn và do khách hàng thanh toán
                <br>
                <b>Product Tax: </b>Thuế áp dụng trên tổng giá sản phẩm bán được. Số tiền này được cộng vào hóa đơn và do khách hàng thanh toán
            </p>
        </div>
    `,
    span: `
        <div>
            <p>
                <b>Service Tax: </b>Impuesto que se aplica sobre el precio total del servicio de una visita. Esta cantidad se agrega a la factura y es pagada por los clientes
                <br>
                <b>Product Tax: </b>Impuesto que se aplica sobre el precio total del producto de una visita. Esta cantidad se agrega a la factura y es pagada por los clientes
            </p>
        </div>
    `
};
const appointmentSetting = {
    eng: `
        <div>
            <p>
                <b>How far in advance customers can book appointments (weeks): </b>This limits how far in advane can a customer book his/her appointment
                <br>
                <b>Allow appointments for today: </b>If this is set to true, a customer can request an appointment for today. If this is set to false, customers can only book an appointment for tomorrow forward
                <br>
                <b>Opening day, openning hour, closing hour: </b>Customers can only request an appointment that happens during openning days of the week, on or after openning hour. The appointment also has to end before closing hour
                <br>
                Besides store's business hours, customers who request a certain technician are only allowed to book an appointment within that technician's working hours also. A technician can edit his/her working hours in the staff interface
            </p>
        </div>
    `,
    vi: `
        <div>
            <p>
                <b>How far in advance customers can book appointments (weeks): </b>Khách hàng có thể đặt lịch hẹn trước bao nhiêu tuần. Điều này giới hạn khoảng thời gian khách hàng có thể đặt lịch hẹn trước
                <br>
                <b>Allow appointments for today: </b>Cho phép các cuộc hẹn cho hôm nay. Nếu được bật, khách hàng có thể yêu cầu một cuộc hẹn cho ngày hôm nay. Nếu được tắt, khách hàng chỉ có thể đặt lịch hẹn cho ngày mai trở đi
                <br>
                <b>Opening day, openning hour, closing hour: </b>Khách hàng chỉ có thể yêu cầu một cuộc hẹn diễn ra vào các ngày mở cửa trong tuần, vào hoặc sau giờ mở cửa. Cuộc hẹn cũng phải kết thúc trước giờ đóng cửa
                <br>
                Bên cạnh ngày giờ làm việc của cửa hàng, khách hàng nếu có yêu cầu một kỹ thuật viên nhất định thì cũng phải tuân thủ thêm ngày giờ làm việc của kỹ thuật viên đó. Kỹ thuật viên có thể chỉnh sửa giờ làm việc của mình trong giao diện nhân viên
            </p>
        </div>
    `,
    span: `
        <div>
            <p>
                <b>How far in advance customers can book appointments (weeks): </b>Con cuánta anticipación los clientes pueden reservar citas (semanas). Esto limita la anticipación con la que un cliente puede reservar su cita
                <br>
                <b>Allow appointments for today: </b>Permitir citas para hoy. Si se establece como verdadero, un cliente puede solicitar una cita para hoy. Si se establece en falso, los clientes solo pueden reservar una cita para mañana en adelante
                <br>
                <b>Opening day, openning hour, closing hour: </b>Los clientes solo pueden solicitar una cita que ocurra durante los días de apertura de la semana, en o después de la hora de apertura. La cita también debe finalizar antes de la hora de cierre
                <br>
                Además del horario comercial de la tienda, los clientes que soliciten un determinado técnico solo pueden reservar una cita dentro del horario laboral de ese técnico. Un técnico puede editar sus horas de trabajo en la interfaz de personal
            </p>
        </div>
    `
};
const visitProcessing = {
    eng: `
        <div>
            <p>                
                <b>To change technician or service: </b>Click on the row
                <br>
                <b>To remove a service from the visit: </b>Click on the ${parseIcon('minus-circle', "primary")} on the service row
                <br>
                <b>To mark a service complete: </b>Click on the ${parseIcon('check-circle', 'primary')} on the service row. All the services have to be mark complete before payment info can be filled in
                <br>
                ${parseIcon('plus-circle', 'success')}: Add services
                <br>
                ${parseIcon('file-invoice', 'success')}: Visit summary. A summary that is optimized to be viewed by customers. Tips can be added in this interface
                <br>
                ${parseIcon('cash-register', 'info')}: Payment information. Cashier can input Tips, product sales, promotion/discount, payment method (cash or card) or calculate changes in this interface
                <br>
                ${parseIcon('check-circle', 'primary')}: Mark a visit complete. After payment information is complete, users can mark a visit complete and this visit will be hidden. Complete visit can be viewed in "Complete" section
                <br>
                ${parseIcon('trash-alt', 'secondary')}: Delete a visit
            </p>
        </div>
    `,
    vi: `
        <div>
            <p>
                <b>Để thay đổi kỹ thuật viên hoặc dịch vụ: </b>Nhấp vào hàng chứa dịch vụ cần thay đổi.
                <br>
                <b>Để xóa một dịch vụ khỏi lượt khách: </b>Nhấp vào ${parseIcon('minus-circle', "primary")} trên hàng dịch vụ.
                <br>
                <b>Để đánh dấu một dịch vụ đã hoàn tất: </b>Nhấp vào ${parseIcon('check-circle', 'primary')}  trên hàng dịch vụ. Tất cả các dịch vụ phải được đánh dấu hoàn tất trước khi có thể điền thông tin thanh toán.
                <br>
                ${parseIcon('plus-circle', 'success')}: Thêm dịch vụ
                <br>
                ${parseIcon('file-invoice', 'success')}: Tóm tắt hóa đơn khách. Bản tóm tắt được tối ưu hóa để khách hàng xem dễ dàng. Tiền tip có thể được điền vào trên giao diện này.
                <br>
                ${parseIcon('cash-register', 'info')}: Thông tin thanh toán. Thu ngân có thể nhập tiền tip, bán sản phẩm, khuyến mãi / chiết khấu, phương thức thanh toán (tiền mặt hoặc thẻ) hoặc tính tiền thừa trong giao diện này.
                <br>
                ${parseIcon('check-circle', 'primary')}: Đánh dấu một khách hàng đã hoàn tất. Sau khi thông tin thanh toán hoàn tất, người dùng có thể đánh dấu một khách hàng là hoàn tất và lượt khách này sẽ bị ẩn đi. Toàn bộ thông tin các khách đã hoàn thành có thể được xem trong phần "Complete".
                <br>
                ${parseIcon('trash-alt', 'secondary')}: Xóa lượt khách.
            </p>
        </div>
    `,
    span: `
        <div>
            <p>
                <b>Para cambiar de técnico o servicio: </b>Haga clic en la fila
                <br>
                <b>Para eliminar un servicio de la visita: </b>haga clic en el ${parseIcon('minus-circle', "primary")} en la fila del servicio
                <br>
                <b>Para marcar un servicio como completo: </b>Haga clic en el botón ${parseIcon('check-circle', 'primary')} en la fila del servicio. Todos los servicios deben marcarse como completos antes de que se pueda completar la información de pago
                <br>
                ${parseIcon('plus-circle', 'success')}: Agregar servicios
                <br>
                ${parseIcon('file-invoice', 'success')} resumen de la visita. Un resumen optimizado para que lo vean los clientes. Se pueden agregar sugerencias en esta interfaz
                <br>
                ${parseIcon('cash-register', 'info')}: Información de pago. El cajero puede ingresar propinas, ventas de productos, promoción / descuento, método de pago (efectivo o tarjeta) o calcular cambios en esta interfaz
                <br>
                ${parseIcon('check-circle', 'primary')} Marque una visita como completada. Una vez completada la información de pago, los usuarios pueden marcar una visita como completa y esta se ocultará. La visita completa se puede ver en la sección "Complete"
                <br>
                ${parseIcon('trash-alt', 'secondary')}: Eliminar una visita
            </p>
        </div>
    `
};
export default {
    add_staff_content: addStaffModal,
    add_service_content: addServiceModal,
    tax_setting_content: taxSetting,
    appointment_setting_content: appointmentSetting,
    visit_processing_content: visitProcessing
}