import React from 'react'
import {Modal, ModalBody, Row, Col} from 'reactstrap';
import {
    FaQuestionCircle
} from 'react-icons/fa'
import { getColor } from '../../utils/colors';
import {toggleHelperModal} from '../../store/actions/action'
import {getContentFromKey, parseHTMLString} from "../../utils/Utils";
import LanguageSwitchDropDown from "../LanguageSwitchDropDown";
import {connect} from 'react-redux';
import { DeleteBtn } from "../Btn";
import helperData from "../../demos/helpModalData";
import "./style.css";

export function HelperModal({
    language,
    isOpen,
    toggleHelperModal,
    showKey
}) {
    let multiLanguageModal = helperData[showKey];
    const content = multiLanguageModal ? multiLanguageModal[language] : "";
    
    return (
       <Modal
            isOpen = {isOpen}
            toggle = {() => toggleHelperModal(showKey)}
            scrollable = {true}
            size = "lg"
            className = "help-modal"
        >
           <ModalBody>
                <DeleteBtn onClick={() => toggleHelperModal(showKey)} className="float-right" />   
                <h3 className= "text-secondary mb-2">
                <FaQuestionCircle size={25} />&nbsp; Quick Info
                </h3>
                <LanguageSwitchDropDown className = "mt-1 mb-2"/>
                <Row>
                    <Col xs = "10">
                        <div 
                        dangerouslySetInnerHTML = {parseHTMLString(content)}
                        >
                        </div>
                    </Col>
                </Row>

           </ModalBody>
       </Modal>
    )
}

const mapStateToProps = state => {
    return {
        isOpen: state.isOpenHelperModal,
        showKey: state.openHelperKey,
        language: state.defaultLanguage
    };
};

const mapDispatchToProps = dispatch => {
    return {
        toggleHelperModal: () => dispatch(toggleHelperModal())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(HelperModal);