import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reducer from "./store/reducers/reducer";
import {Provider} from "react-redux";
import {createStore, applyMiddleware} from "redux";
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import "../node_modules/react-datepicker/dist/react-datepicker.css";
import '../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import '../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
// import '../node_modules/bootstrap/dist/css/bootstrap.css'
import HelperModal from './components/HelperModal';
const sagaMiddleware = createSagaMiddleware();
const store = createStore(reducer, applyMiddleware(sagaMiddleware))
sagaMiddleware.run(rootSaga)

function renderModals() {
    return (
        <React.Fragment>
            <HelperModal />
        </React.Fragment>
    )
}


ReactDOM.render(
<Provider store={store}>
    <App /> 
    {renderModals()}
</Provider>
, document.getElementById("root")
);

