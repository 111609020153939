import React from 'react';
import {
  FaLock
} from 'react-icons/fa';
import expiredIcon from '../../assets/img/expired.svg';
import {
    Card,
    CardBody,
    Col,
    Row,
    Button,
  } from 'reactstrap';
import './style.css';

export default function({
    onUpgrade
}) {

    return (
        <div class = "upgradeblocks">
            <div class = "d-flex flex-column justify-content-center align-items-center upgradeblocks-contain">

                <img
                    src={expiredIcon}
                    alt="Expired plan"
                    height="87"
                    width="100"
                    className="mb-2"
                />
                <h4 className="text-muted mb-4">Your trial has expired or your account needs an upgrade</h4>

                <Button color = "secondary" className = "upgradeblocks-button" onClick = {onUpgrade}>
                    Upgrade
                </Button>
                <p className="mb-0 mt-1 text-muted">or</p><span className="form-text text-muted">Contact us via hello@ahasalon.com</span> 
            </div>
        </div>
    )
}