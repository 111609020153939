import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from "react-router-dom";
import AuthPage from './pages/AuthPage';
import EmployeeAuth from './pages/EmployeeAuth';
import BranchPage from './pages/BranchPage';
import PaymentSucessPage from './pages/PaymentSuccess';
import UnsubscribePage from './pages/Unsubscribe';
import BookingPage from './pages/Booking';
import { STATE_LOGIN, STATE_SIGNUP } from './components/AuthForm';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './assets/css/react_dates_overrides.css';
// import Waitlist from "./pages/Waitlist";
// import CheckIn from "./pages/CheckIn";
// import Manager from "./pages/Manager";
import { EmptyLayout, LayoutRoute, MainLayout } from "./components/Layout";
import componentQueries from 'react-component-queries';
import PageSpinner from './components/PageSpinner';
import './styles/reduction.scss';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStroopwafel } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { setUserData, setCurrentRole, setCurrentStore,
   fetchTrialExpired, fetchActivePlanState, fetchAllContent,
    fetchDefaultLanguage, fetchPendingAppointmment } from './store/actions/action';
import { useState } from "react";
import RedirectPage from "./components/RedirectPage";
//for employee
const EmployeeDashboardPage = React.lazy(() => import('./pages/EmployeeDashboard'));
const EmployeeCalendarPage = React.lazy(() => import('./pages/EmployeeCalendar'));
const EmployeeAppointmentPage = React.lazy(() => import('./pages/EmployeeAppointmentSetting'));

const WaitlistPage = React.lazy(() => import('./pages/Waitlist'));
const CompletePage = React.lazy(() => import('./pages/Complete'));
const CheckInPage = React.lazy(() => import('./pages/CheckIn'));
const ManagerPage = React.lazy(() => import('./pages/Manager'));
const StoreReportPage = React.lazy(() => import('./pages/StoreReport'));
const EmployeeReportPage = React.lazy(() => import('./pages/EmployeeReport'));
const CustomerReportPage = React.lazy(() => import('./pages/CustomerReport'));
// const ManagerPage = React.lazy(() => import('./pages/Manager'));
const CalendarPage = React.lazy(() => import('./pages/Calendar'));
const AccessLinkPage = React.lazy(() => import('./pages/AccessLinkPage'));
// const RawPage = React.lazy(() => import('./pages/Raw'));
const PromotionPage = React.lazy(() => import('./pages/Promotion'));
const SmsPage = React.lazy(() => import('./pages/Sms'));
const SettingPage = React.lazy(() => import('./pages/Setting'));
const StoreSettingPage = React.lazy(() => import('./pages/StoreSetting'));
const AccountPage = React.lazy(() => import('./pages/AccountPage'));
// const PaymentSucessPage = React.lazy(() => import('./pages/PaymentSuccess'));
// const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
// const BadgePage = React.lazy(() => import('pages/BadgePage'));
// const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
// const ButtonPage = React.lazy(() => import('pages/ButtonPage'));

library.add(faStroopwafel)
const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

const CONSTANT = require('./utils/Const');

class App extends React.Component {

  componentDidUpdate() {

  }

  componentDidMount() {
    let userData = localStorage.getItem(CONSTANT.key.USER);

    if (userData && userData.length > 0) {
      let data = JSON.parse(userData);
      this.props.setUserData(data);
      let currentStoreState = '';
      let currentStoreData = localStorage.getItem(CONSTANT.key.STORE);
      if (currentStoreData && currentStoreData.length > 0) {
        currentStoreState = JSON.parse(currentStoreData);
      } else {
        console.log('this.props.currentStoreState ----- ', this.props.currentStoreState);
        currentStoreState = this.props.currentStoreState;
      }
      console.log('currentStoreState ----- ', currentStoreState);
      this.props.setCurrentStore(currentStoreState);


      if (userData) {
        // Presist user data
        this.props.fetchActivePlanState({
          id: data.id,
          timeLiveStoreURLToken: data.timeLiveStoreURLToken
        })

        this.props.fetchTrialExpired({
          timeLiveStoreURLToken: data.timeLiveStoreURLToken
        })
        this.props.fetchAllContent()
        this.props.fetchDefaultLanguage({
          id: data.id
        })

        this.props.fetchPendingAppointmment({
          store: currentStoreState._id
        })
      } 
      
    }


    /*window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return ev.returnValue = 'Are you sure you want to close?';
    });*/
  }


  renderRouter(component) {
    if (window.location.pathname === '/paymentsuccess' || window.location.pathname.startsWith('/unsubscribe')) {
      return component;
    }

    const userData = this.props.userState;
    const trialDataState = this.props.trialDataState;
    if (userData.id && userData.id.length > 0) {
      
      const role = this.checkRolePathByUser(userData);
      this.props.setCurrentRole(role);
      
      if (userData.account_plan === 'trial') {
        const now = Date.now() / 1000;
        
        if (trialDataState.length == 0) {
          return <Redirect to="/login" />
        } 
        
        // if (now > trialDataState.expired_date) {
        //   return <Redirect to="/login" />
        // }
        //
      }
      if (userData.manager_role) return component;
      if (userData.receptionist_role) return component;
      if (userData.customer_role) return component;
      if (userData.employee_role) return component;

    }

    return <Redirect to="/login" />
  }

  getCurrentRoleFromPath() {
    if (window.location.pathname.indexOf('manager') !== -1) return 'manager';
    if (window.location.pathname.indexOf('accesslink') !== -1) return 'manager';
    if (window.location.pathname.indexOf('front') !== -1) return 'front';
    if (window.location.pathname.indexOf('customer') !== -1) return 'customer';
    if (window.location.pathname.indexOf('employee') !== -1) return 'employee';
  }

  checkRolePathByUser(userData) {
    if (userData.manager_role) return "manager";
    if (userData.receptionist_role) return "front";
    if (userData.customer_role) return "customer";

    return ""
}

  getBranchName() {
    let currentBranchName = '';
    let currentStoreId = '';
    let currentStoreData = localStorage.getItem(CONSTANT.key.STORE);

    let currentStoreState = '';
    let { allStoreState, userState } = this.props;

    if (currentStoreData && currentStoreData.length > 0) {
      currentStoreState = JSON.parse(localStorage.getItem(CONSTANT.key.STORE));
    } else {
      currentStoreState = this.props.currentStoreState;
    }

    if (currentStoreState.currentStore !== '') {
      currentBranchName = currentStoreState.currentStore.branchName;

    } else {
      currentStoreId = userState.default_store;
      for (let i = 0; i < allStoreState.length; i++) {
        if (allStoreState[i]._id === currentStoreId) {
          currentBranchName = allStoreState[i].branchName;
          currentStoreState.currentStore = allStoreState[i];
          currentStoreState.index = i;
          localStorage.setItem(CONSTANT.key.STORE, JSON.stringify(currentStoreState));
          break;
        }
      }
    }
    return currentBranchName;
  }

  render() {
    const branchName = this.getBranchName();
    return (
      <Router basename={getBasename()}>
        <Switch>
          <LayoutRoute
            exact
            path="/"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/branch"
            layout={EmptyLayout}
            component={props => (
              <BranchPage {...props} />
            )}
          />
          <LayoutRoute
            exact
            path="/:branchName/staff"
            layout={EmptyLayout}
            component={props => (
              <EmployeeAuth {...props} />
            )}
          />
          <LayoutRoute
            exact
            path="/:branchName/booking"
            layout={EmptyLayout}
            component={props => (
              <BookingPage {...props} />
            )}
          />
          <LayoutRoute
            exact
            path="/paymentsuccess"
            layout={EmptyLayout}
            component={props => (
              <PaymentSucessPage {...props} />
            )}
          />
          <LayoutRoute
            exact
            path="/unsubscribe"
            layout={EmptyLayout}
            component={props => (
              <UnsubscribePage {...props} />
            )}
          />
          {/* <Route
            path="/:branchName/account_setup"
            layout={EmptyLayout}
            component={props => (
              <CreateStorePage {...props} />
            )}
          /> */}
          <MainLayout breakpoint={this.props.breakpoint}>
            {branchName && branchName.length > 0
              ?
              <React.Suspense fallback={<PageSpinner />}>
                {/* <Route exact path="/customer" component={props => <WaitlistPage {...props} />} /> */}
                {/* employee */}
                {this.renderRouter(<Route exact path={"/:branchName/staff/dashboard"} component={props => <EmployeeDashboardPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/staff/calendar"} component={props => <EmployeeCalendarPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/staff/appointmentsetting"} component={props => <EmployeeAppointmentPage {...props} />} />)}
                {/* front */}
                {this.renderRouter(<Route exact path={"/:branchName/customer"} component={props => <WaitlistPage {...props} />} />)}
                {/* {this.renderRouter(<Route exact path={"/:branchName/booking"} component={props => <BookingPage {...props} />} />)} */}
                {this.renderRouter(<Route exact path={"/:branchName/front"} component={props => <CheckInPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/front/calendar"} component={props => <CalendarPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/front/checkin"} component={props => <WaitlistPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/front/complete"} component={props => <CompletePage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/front/setting"} component={props => <SettingPage {...props} />} />)}
                {/** manager */}
                {this.renderRouter(<Route exact path={"/:branchName/manager"} component={props => <ManagerPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/accesslink"} component={props => <AccessLinkPage {...props} />} />)}
                {/* {this.renderRouter(<Route exact path="/manager/raw" component={props => <RawPage {...props} />} />)} */}
                {this.renderRouter(<Route exact path={"/:branchName/manager/storesetting"} component={props => <StoreSettingPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/manager/promotion"} component={props => <PromotionPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/manager/storereport"} component={props => <StoreReportPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/manager/staffreport"} component={props => <EmployeeReportPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/manager/custandgroup"} component={props => <CustomerReportPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/manager/complete"} component={props => <CompletePage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:banchName/manager/calendar"} component={props => <CalendarPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/manager/sms"} component={props => <SmsPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/:branchName/manager/setting"} component={props => <SettingPage {...props} />} />)}
                {this.renderRouter(<Route exact path={"/manager/accounts"} component={props => <AccountPage {...props} />} />)}
                {/* {this.renderRouter(<Route exact path="/manager/paymentsuccess" component={props => <PaymentSucessPage {...props} />} />)} */}
                {this.renderRouter(<Route exact path={"/:branchName"} component={props => <RedirectPage {...props} />} />)}
              </React.Suspense>
              : null
            }

          </MainLayout>
          <Redirect to="/login" />
        </Switch>
      </Router>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

function mapStateToProps(state) {
  return {
    userState: state.userData,
    allStoreState: state.allStoreList,
    currentStoreState: state.currentStore,
    trialDataState: state.trialData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setUserData: (data) => dispatch(setUserData(data)),
    setCurrentRole: (data) => dispatch(setCurrentRole(data)),
    setCurrentStore: (data) => dispatch(setCurrentStore(data)),
    fetchTrialExpired: (data) => dispatch(fetchTrialExpired(data)),
    fetchActivePlanState: (data) => dispatch(fetchActivePlanState(data)),
    fetchAllContent: () => dispatch(fetchAllContent()),
    fetchDefaultLanguage: (data) => dispatch(fetchDefaultLanguage(data)),
    fetchPendingAppointmment: (data) => dispatch(fetchPendingAppointmment(data))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(componentQueries(query)(App));
