import React, { Component } from "react";
// import bgImage from '../assets/img/pastelbg.jpg';
import Page from '../components/Page';
import API from "../utils/API";
import ahaLogo from '../assets/img/logo/logo_200.png';
import { RenderServiceListBlock, RenderSelectedServiceListBlock } from "../components/ServiceListBlock";
import { connect } from "react-redux";
import { fetchAvailableService, fetchGarellyImages } from "../store/actions/action";
import PageSpinner from '../components/PageSpinner';
// import { ServiceListListItem, ShowHideBlock } from "../components/ServiceListBlock";
import socketIOClient from "socket.io-client";
// import { toastSuccess, toastFailure, swal_confirm } from "../utils/toast_mixin";
// import DatePicker from "react-datepicker";
// import { Mtable } from "../components/Mtable";
// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getColor } from '../utils/colors';
import {
  validatePhoneNumber,
  randomString
} from "../utils/Utils";
import { v1 as uuidv1 } from 'uuid';
// import { withRouter } from 'react-router-dom';
import {
  FaForward, FaCalendarCheck, FaClipboardCheck, FaBookmark, FaBackward, FaCheckDouble, FaPlusSquare, FaCheckCircle
} from 'react-icons/fa'
import {
  Label,
  Card,
  CardText,
  CardBody,
  Col,
  Row,
  FormGroup,
  Form,
  Input,
  Button, CardHeader, CardFooter
} from 'reactstrap';
import AppSingleton from '../utils/AppSingleton';
import moment from 'moment';
import DatePicker from "react-datepicker";
import 'moment-timezone';
import Slider from "react-slick";
import PhoneCodeSelector from "../components/PhoneCodeSelector"
// const moment = require("moment");
const WAIT_INTERVAL = 1500;
const CONSTANT = require('../utils/Const');
// var lastStoreId = "5dbe01fa7009c516e846fee6";
const secondaryColor = getColor('secondary');

const customerMessage = CONSTANT.CUSTOMER_MESSAGE;
const storeMessage = CONSTANT.STORE_MESSAGE;
const tempRemindMessage = CONSTANT.TEMP_REMIND_MESSAGE;
const sentMessageState = CONSTANT.deliveryMessageState;

const smsContent = {
  c_confirm:  "Reply \"Y{{appointment_number}}\" to confirm your request for appointment at {{datetime}}, {{store_name}}",
  c_confirm_w_store: "Reply \"Y{{appointment_number}}\" to confirm your request for appointment at {{datetime}}, {{store_name}}. Please wait for the store's confirmation.",
  c_confirmed: "Appointment confirmed at {{datetime}}, {{store_name}}. Thank you!",
  store_confirmed: "Appointment confirmed for {{staff}, at {{datetime}}."
}
class Waitlisttotal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      service_ready: false,
      date_ready: false,
      email: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      phone_num: "",
      selectedDate: moment().format("YYYY-MM-DD"),
      selectedService: [],
      dateRangeWarning: "",
      dateMin: new Date(),
      dateMax: new Date(),
      availability: [],
      availability_ready: false,
      new_event_success: false,
      customer_list: [],
      customer_selected: null,
      store_list: [],
      lastStoreId: '',
      lastStoreName: '',
      lastStoreAddress:'',
      lastStoreCity: '',
      lastStoreState: '',
      lastStoreLogoImage: '',
      newEventAlert: '',
      allow_contact: true,
      phone_code: "+1"
    }

    this.socket = AppSingleton.getInstanceSocket();
  }

  timer = null

  // sending sockets
  send = () => {
    // const socket = socketIOClient(CONSTANT.endpoint);
    this.socket.emit('ClientChangesData');
  }
  cleaninput() {
    this.setState({
      // modal_detail: false,
      email: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      phone_num: "",
      phone_code: "+1",
      selectedService: [],
      new_event_success: false,
      step: 1,
      empDat: "",
      availability_ready: false,
      customer_list: [],
      customer_selected: null
    })
  }
  toggle(modalType) {
    if (!modalType) {
      return this.setState({
        modal: !this.state.modal,
      });
    }
    this.setState({
      [`modal_${modalType}`]: !this.state[`modal_${modalType}`],
    });
  }
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1
    }, () => {
      switch (step) {
        case 1:
          this.getAvailability()
          break;

        default:
          break;
      }
    })
  }

  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    })
  }

  // componentDidUpdate() {
  //   const { id } = this.props.currentStoreState;
  //   if (lastStoreId !== id) {
  //     this.props.onfetchWaitlistPageFull({ store: id });
  //     lastStoreId = id;
  //   }
  // }
  getStoreSetting() {
    const { lastStoreId } = this.state;
    const { currentStore } = this.props.currentStoreState;
    API.getStoreDetails(lastStoreId)
      .then(res => {
        const {appt_max_week_from_now } = res.data;
        let dateMin = currentStore.same_day_appointment ? new Date() : moment().add(1, 'days').toDate();
        dateMin = new Date(dateMin.setHours(0, 0, 0, 0));
        let dateMax = moment().add(appt_max_week_from_now, 'w').toDate();
        dateMax = new Date(dateMax.setHours(23, 59, 59, 999));
        let selectedDate = currentStore.same_day_appointment ? moment(dateMin).format("YYYY-MM-DD") : moment().add(1, 'days').format("YYYY-MM-DD");
        this.setState({
          dateMin,
          dateMax,
          selectedDate,
          date_ready: true,
        })
      })
      .catch(error => {
        console.log(error);
      })
  }
  getAvailability() {
    const { selectedDate, selectedService, lastStoreId } = this.state;
    let services = this.props.service.filter(function (value, index, arr) { return selectedService.includes(value._id) });
    let query_time = services.reduce((accumulator, currentValue, currentIndex, array) => { return accumulator + currentValue.duration_min }, 0)

    API.getAvailableAppointment({
      selectedDate: this.convertServerTZ(selectedDate),
      query_time: query_time,
      store: lastStoreId
    })
      .then(res => {
        this.setState({
          availability: res.data,
          availability_ready: true,
        })
      })
      .catch(error => {
        console.log(error);
      })
  }

  /* 
    Merge time between staff appointment and store appointment
  */
  filterAvailabilityDate = (availability) => {
    const { selectedDate, selectedService } = this.state;
    const store = this.state.store_list[0];
    const {store_opening_day, store_opening_hour, store_closing_hour} = store;

    let services = this.props.service.filter(function (value, index, arr) { return selectedService.includes(value._id) });
    let query_time = services.reduce((accumulator, currentValue, currentIndex, array) => { return accumulator + currentValue.duration_min }, 0)

    const staffAvailable = availability.map(availableEmp => {
      const {start_time, end_time, avai, id, name, store} = availableEmp;
      const targetDate = new Date(start_time);
      const endD = new Date(end_time)
      const dayInWeek = targetDate.getDay();
      const openingStoreHours = store_opening_hour[dayInWeek];
      const closingStoreHours = store_closing_hour[dayInWeek];
      const isOpeningThisDay = store_opening_day[dayInWeek];
      console.log(targetDate, start_time);
      console.log("isOpenThatDay: ", isOpeningThisDay, dayInWeek)
      // store not open targe day
      if (!isOpeningThisDay) {
        return {
          avai: [],
          start_time,
          end_time,
          id,
          name,
          store
        }
      }

      let storeOpenDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(),
                            Math.floor(openingStoreHours / 100), openingStoreHours % 100, 0);
      let storeEndDate = new Date(endD.getFullYear(), endD.getMonth(), endD.getDate(),
                           Math.floor(closingStoreHours / 100), closingStoreHours % 100, 999);
      storeOpenDate = this.convertServerTZ(storeOpenDate.toISOString());
      storeEndDate = this.convertServerTZ(storeEndDate.toISOString());
      
      console.log("origin: ", avai);
      console.log("origin time: ",storeOpenDate,  storeEndDate)
      console.log("open store date: ", storeOpenDate.toISOString());
      console.log("close store date: ", storeEndDate.toISOString());

      const newAvai = avai.filter(pickDate => {
          const sDate = new Date(start_time);
          const eDate = new Date(end_time);
          const filterStartDate = sDate >= storeOpenDate ? sDate : storeOpenDate;
          const filterEndDate = eDate <= storeEndDate ? eDate : storeEndDate;
          const startDate = new Date(pickDate.start);
          const endDate = new Date(pickDate.end);

          if (startDate >= filterStartDate && endDate <= filterEndDate) {
            return true;
          }
          return false;
      })

      console.log("after: ", newAvai)
      
      return {
        avai: newAvai,
        start_time,
        end_time,
        id,
        name,
        store
      }

    })

    // Create anyone available list base on open hour store and end hour store
    let anyoneAvailableHours = [];
    const targetDate = new Date(selectedDate)
    const currentOpeningStoreHours = store_opening_hour[targetDate.getDay()];
    const currentClosingStoreHours = store_closing_hour[targetDate.getDay()];
    const isOpeningThisDay = store_opening_day[targetDate.getDay()];
    
    // store not open targe day
    if (!isOpeningThisDay) {
     return [staffAvailable, {
      avai: [],
      start_time: targetDate.toISOString(),
      end_time: targetDate.toISOString(),
      name: "Anyone",
      store
     }];
    }
    let storeOpenDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(),
                            Math.floor(currentOpeningStoreHours / 100), currentOpeningStoreHours % 100, 0);
    let storeEndDate = new Date(targetDate.getFullYear(), targetDate.getMonth(), targetDate.getDate(),
                          Math.floor(currentClosingStoreHours / 100), currentClosingStoreHours % 100, 999);
    storeOpenDate = this.convertServerTZ(storeOpenDate.toISOString());
    storeEndDate = this.convertServerTZ(storeEndDate.toISOString());
    let start = storeOpenDate;
    let end = new Date(start.getTime() + 60000 * query_time);
    do {
      end = new Date(start.getTime() + 60000 * query_time);
      if (end > storeEndDate) {
        break;
      }

      anyoneAvailableHours.push({
        start: start.toISOString(),
        end: end.toISOString()
      });
      start = end;
    } while (start < storeEndDate)
    
    let anyoneeAvailable = {
      avai: anyoneAvailableHours,
      start_time: storeOpenDate.toISOString(),
      end_time: storeEndDate.toISOString(),
      name: "Anyone",
      store
    }
    return [staffAvailable, anyoneeAvailable];
  }

  componentDidMount() {
    const branchNameFromPath = window.location.pathname.split('/')[1];
    if (branchNameFromPath && branchNameFromPath.length > 0){
          this.getStoreList(branchNameFromPath);
    }
  }


  validateSelectedDate = () => {
    const { dateMin, dateMax, selectedDate } = this.state;

    let pickedDate = new Date(selectedDate)
    pickedDate = new Date(pickedDate.setHours(23, 59, 59, 0))
    console.log('selected: ', pickedDate)
    console.log('datemin: ', dateMin)
    console.log('datemax: ', dateMax)
    console.log('compare input < dateMin: ', pickedDate < dateMin)
    console.log('compare input < dateMax: ', pickedDate < dateMax)
    if ((pickedDate < dateMin) || (pickedDate > dateMax)) {
      this.setState({
        dateRangeWarning: "Date must be between " + moment(dateMin).format("YYYY/MM/DD") + " and " + moment(dateMax).format("YYYY/MM/DD")
      })
    } else {
      this.setState({
        dateRangeWarning: ""
      })
    }
  }

  handleInputChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    }, () => {
      switch (name) {
        case "selectedDate":
          console.log("date change: " , value);
          this.validateSelectedDate();
          break;
        case "phone_num":
          this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
          break;
        default:
          break;
      }
    });
  };

  addService = serviceId => {
    let { selectedService } = this.state;
    selectedService.push(serviceId)
    this.setState({
      selectedService
    })
  }

  removeService = serviceId => {
    let { selectedService } = this.state;
    let filtered = selectedService.filter(function (value, index, arr) { return value !== serviceId; })
    this.setState({
      selectedService: filtered
    })
  }

  renderAvailabilityBox = () => {
    const { availability } = this.state;
    const [filerAvailability, anyoneAvaibility] = this.filterAvailabilityDate(availability)
    console.log("before ", anyoneAvaibility);
    console.log("Filter ", filerAvailability)
    let empList = [];
    if (filerAvailability.length) {
      empList.push(
          <div 
          style={{ marginLeft: 15, marginTop: 25 }}
          key={`emp_booking_name_anyone`}>
            <span key={`emp_booking_anyone`}>Anyone Available</span> 
            <hr style={{ marginTop: 5 }}></hr>
          </div>
      );

      empList.push(
        <div key={`autoCol_anyone`} className="auto-col">
          { 
            anyoneAvaibility.avai.length > 0 ?
              anyoneAvaibility.avai.map(appt => {
                return (
                  <Button
                    onClick={() => this.handleClickOnAppointment({
                      empId: null,
                      start: appt.start,
                      end: appt.end
                    })}
                    key={`anyone${moment(appt.start).format('HH:mm')}`}
                    color={'warning'}
                    className="mr-2 mb-2 time-block"
                  >
                     {moment(appt.start).tz(CONSTANT.TIMEZONE).format('HH:mm')} {} {"-"}{}{moment(appt.end).tz(CONSTANT.TIMEZONE).format('HH:mm')}
                  </Button>
                )
              })
            : 
              (
                <CardText>
                  <strong>(Not available)</strong>
                </CardText>
              )
          }
        </div>
      )


      for (let emp in filerAvailability) {
        let empValue = filerAvailability[emp]
        empList.push(<div style={{ marginLeft: 15, marginTop: 25 }} key={`emp_booking_name${empValue.id}`}><span key={`emp_booking${empValue.id}`}>{empValue.name}</span> <hr style={{ marginTop: 5 }}></hr></div>);
        empList.push(
          empValue.avai.length ? (
            <div key={`autoCol${empValue.id}`} className="auto-col">
              {empValue.avai.map(appt => (
                <Button
                  onClick={() => this.handleClickOnAppointment({
                    empId: empValue.id,
                    start: appt.start,
                    end: appt.end
                  })}
                  key={`${empValue.id}${moment(appt.start).format('HH:mm')}`}
                  color={'warning'}
                  className="mr-2 mb-2 time-block"
                >
                  {moment(appt.start).tz(CONSTANT.TIMEZONE).format('HH:mm')} {} {"-"}{}{moment(appt.end).tz(CONSTANT.TIMEZONE).format('HH:mm')}
                </Button>
              ))}
            </div>
          ) : (
              <CardText>
                <strong>(Not available)</strong>
              </CardText>
            )
        )
      }
    } else {
      empList = (
        <CardText>
          <strong>We're sorry. There is no availability on your selected day.</strong>
        </CardText>
      )
    }
    return empList;
  }

  handleClickOnAppointment = (empData) => {
    this.setState({
      empData
    }, () => { this.nextStep(); console.log('current state: ', this.state) })
  }

  // TODO: get hours and minte from target date and try to set date in differnce timesonz
  // This thing use in context that we need to sync timezone about booking from server and client
  convertServerTZ = (dateStr) => {
    const date = new Date(dateStr);
    const hour = date.getHours();
    const minute = date.getMinutes();
    const cDate = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear()
    const timeZoneDate = moment(date).tz(CONSTANT.TIMEZONE).set({
      hour,
      minute,
      date: cDate,
      month,
      year
    }).toDate()
    return timeZoneDate;
  }

  handleAppointmentSubmit = (event) => {
    event.preventDefault();

    const { empData, phone_num, first_name, last_name, middle_name, selectedService, lastStoreId, lastStoreName, phone_code} = this.state;
    const shortAppointmentCode = randomString(2);
    const appointmentNumber = uuidv1().substr(0, 7).toUpperCase() + "_" + shortAppointmentCode;
    const {storeName} = this.props.currentStoreState.currentStore;
    const fullNumber = phone_code + phone_num;
    // let customerConfirmMessage = customerMessage.replace(/{{appointment_number}}/g, appointmentNumber);
    let storeConfirmMessage = storeMessage.replace(/{{appointment_number}}/g, appointmentNumber);
    storeConfirmMessage = storeConfirmMessage.replace(/{{date}}/g, moment(empData.start).tz(CONSTANT.TIMEZONE).format("hh:mm A MMM DD"));

    let customerConfirmMessage =  this.getMessageFromTemplete(smsContent.c_confirm, {
      appointment_number: shortAppointmentCode,
      datetime: empData.start,
      store_name: lastStoreName
    })

    let remindMessage = this.getRemindMessage(phone_num, last_name, lastStoreName, empData.start, appointmentNumber);
    let newEvent = {
      title: 'OA-'+first_name+" "+last_name,
      start: this.convertServerTZ(empData.start),
      end: this.convertServerTZ(empData.end),
      services: selectedService,
      type: 'online-appointment',
      allDay: false,
      phone_num: fullNumber,
      customer_fname: first_name,
      customer_lname: last_name,
      customer_mname: middle_name,
      resourceId: empData.empId || "fakestoreid",
      empId: empData.empId,
      store: lastStoreId,
      appointmentNumber,
      customerConfirmMessage,
      storeConfirmMessage: storeConfirmMessage,
      isCustomerConfirmed: "na",
      remindMessage
    }
    let eventId = ""
    API.saveEvent(newEvent)
      .then(res => {
        eventId = res.data._id;
        return API.sendSms({
          message: customerConfirmMessage,
          phone_num: fullNumber
        })
        
      })
      .then(sendSMSRes => {
        if (sendSMSRes.data.status) {
          return API.updateEventById(eventId, {
            isCustomerConfirmed: sentMessageState.no_reply
          });
        } else {
          // force to next move
          return Promise.resolve()
        }
      })
      .then(res => {
        this.send()
        let currentStep = this.state.step
        this.setState({ step: currentStep + 1, new_event_success: true });
      })
      .catch(err => console.log(err));
  }

  getRemindMessage(phone_num, last_name, store_name, startTime, appointmentNumber) {
    const branchNameFromPath = window.location.pathname.split('/')[1];
    const stopLinkId = `${phone_num}_${branchNameFromPath}`;
    const stop_link = `${CONSTANT.baseUrl}/unsubscribe?id=${stopLinkId}`;

    let convert_message = tempRemindMessage.replace('{{prefix}}', "");
    convert_message = convert_message.replace(/{{last_name}}/g, last_name);
    convert_message = convert_message.replace(/{{stop_link}}/g, stop_link);
    convert_message = convert_message.replace(/{{store_name}}/g, store_name);
    convert_message = convert_message.replace(/{{Date}}/g, moment(startTime).format("YYYY-MM-DD hh:mm A"));
    convert_message = convert_message.replace(/{{appointment_number}}/g, appointmentNumber);
    return convert_message;
  }

  getMessageFromTemplete = (templete = "", dataObject) => {

    let message = templete;
    message = message.replace(/{{appointment_number}}/g, dataObject.appointment_number);
    message = message.replace(/{{store_name}}/g, dataObject.store_name);
    message = message.replace(/{{datetime}}/g,  moment(dataObject.datetime).tz(CONSTANT.TIMEZONE).format("YYYY-MM-DD hh:mm A"));
    message = message.replace(/{{staff}}/g, dataObject.staff);

    return message;
  }

  checkSubmit = () => {

    return !this.state.first_name || 
          !(validatePhoneNumber(this.state.phone_code + this.state.phone_num)) || 
          !this.state.last_name ||
          !this.state.allow_contact

  }

  renderStepProgressBar(param) {
    return (
      <Row>
        <div className="container_progressbar w-100 mt-3">
          <ul className="progressbar clearfix pl-0">
            <li className={param >= 1 ? 'active' : 'non-active'}>Service {'&'} Date</li>
            <li className={param >= 2 ? 'active' : 'non-active'}>Technician {'&'} Time</li>
            <li className={param >= 3 ? 'active' : 'non-active'}>Contact Info</li>
            <li className={param >= 4 ? 'active' : 'non-active'}>Confirmation</li>
          </ul>
        </div>
      </Row>
    )
  }


  renderStoreInfo = () => {
    
    const { lastStoreName, lastStoreAddress, lastStoreCity, lastStoreState, lastStoreLogoImage} = this.state;
    
    const imageSlides = this.props.garellyImages.map(image => {
      return  {
         original: image.img_src,
         thumbnail: image.img_src,
       }
    })

    return (
      <div className = "booking-logo-container">
        <div className = "row">
          <div className = "col-lg-5 col-md-12 col-sm-12">
            {lastStoreLogoImage ? 
              <img className = "waitlist-logo" src={lastStoreLogoImage}/>
              :
              <img className = "waitlist-logo" src={ahaLogo}/>
            }
          </div>
          <div className = "col-lg-7 col-md-12 col-sm-12">
            <Slider 
                dots = {true}
                infinite = {true}
                speed = {500}
                slidesToShow = {1}
                slidesToScroll = {1}
                centerMode = {true}
                autoplay = {true}
                centerPadding ='60px'
                variableWidth = {true}
                initialSlide ={3}
                responsive = {[
                  {
                    breakpoint: 768,
                    settings: {
                      arrows: false,
                      centerMode: true,
                      slidesToShow: 1,
                      slidesToScroll: 1,
                      initialSlide: 1
                    }
                  },
                  {
                    breakpoint: 641,
                    settings: {
                      arrows: false,
                      centerMode: true,
                      slidesToScroll: 1,
                      slidesToShow: 1,
                      initialSlide: 1,
                    }
                  }
                ]}
              >
                {imageSlides.map((image, index) => {
                  return (
                    <div className = "slider-item" key = {`slider_key_${index}`}>
                      <img src = {image.original}/>
                    </div>
                  )
                })}
            </Slider>
          </div>
        </div>

        <div className="text-left">
          <h3 className="waitlist-store-name">
            {lastStoreName}
          </h3> 
          <p className="waitlist-store-address">
            {lastStoreAddress}, {lastStoreCity}, {lastStoreState}
          </p>
        </div>         
      </div>
    )
  }
  getStoreList = async (branchNameFromPath) => {
    let store_list = [];
    const all_store_result = await API.getAllStore();
    if (all_store_result.status === 200) {
      const allStore = all_store_result.data;
      for (let i = 0; i < allStore.length; i++){
        if (allStore[i].branchName === branchNameFromPath){
          store_list.push(allStore[i]);
          break;
        }
      }
    }

    let newEventAlert = '';
    if (store_list[0].same_day_appointment) {
      newEventAlert = 'The earliest day available is ' + moment().format("YYYY-MM-DD");
    } else {
      newEventAlert = 'The earliest day available is ' + moment().add(1, 'days').format("YYYY-MM-DD");
    }

    this.setState({ store_list, lastStoreId: store_list[0]._id, lastStoreName: store_list[0].storeName, lastStoreAddress:store_list[0].streetAddress, lastStoreCity:store_list[0].city, lastStoreState:store_list[0].state, lastStoreLogoImage:store_list[0].logoImage,  newEventAlert }, () => {
      const { lastStoreId } = this.state; 
      this.props.onfetchAvailableService({ store: lastStoreId });
      this.props.fetchGarellyImages({
        store: lastStoreId
      })

      if (!this.state.date_ready) { this.getStoreSetting() };
      
      this.socket.on("DatabaseRefresh", () => this.props.onfetchAvailableService({ store: lastStoreId }));
    });
  }

  renderDropDownStore(store_list) {
    return store_list.map((store_item, index) => {
      if (store_item.enabled)
        return (
          <option
            key={index}
            value={store_item._id}
          >
            {store_item.storeName}
          </option>
        )
      return null
    })
  }

  renderStepSwitch(param) {
    let {date_ready, availability_ready, new_event_success} = this.state;
    switch (param) {
      case 1:
        return (
          <div className = "mt-4">
            {/* <Card className="mb-2">
              <CardHeader>
                <FaClipboardCheck size={'1.2em'} className="card-header-icon text-secondary" /> Select store(s)
              </CardHeader>
              <CardBody>
                <Input
                  type="select"
                  value={lastStoreId}
                  onChange={event => this.setState({ lastStoreId: event.target.value })}
                  name="default_store">
                  {this.renderDropDownStore(store_list)}
                </Input>
              </CardBody>
            </Card> */}
            <Card className="mb-2">
              <CardHeader>
                <FaClipboardCheck size={'1.2em'} className="card-header-icon text-secondary" /> Select service(s)
              </CardHeader>
              <CardBody>
                <RenderSelectedServiceListBlock
                  service_raw={this.props.service}
                  handleRemove={this.removeService}
                  selectedService={this.state.selectedService}
                />
                <hr/>
                <RenderServiceListBlock
                  service_raw={this.props.service}
                  handleClick={this.addService}
                  selectedService={this.state.selectedService}
                />
                {/* {this.renderServiceBox()} */}
                {/* {service_ready?this.renderServiceBox():(<PageSpinner/>)} */}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <FaCalendarCheck size={'1.2em'} color={secondaryColor} className="card-header-icon text-secondary" /> Pick a Date
              </CardHeader>
              <CardBody>
                {date_ready ? (
                  <Form>
                    <FormGroup>
                      <DatePicker
                        value={moment(this.state.selectedDate).toDate()}
                        selected = {moment(this.state.selectedDate).toDate()}
                        onChange = {(date) => {
                          this.handleInputChange({
                              target: {
                                name: "selectedDate",
                                value: date
                              }
                          })
                        }}
                        id="dobinput2"
                        placeholderText = "Select Date"
                        className = "border border-1"
                        dateFormatCalendar = "yyyy-M-dd"
                        dateFormat = "yyyy-M-dd"
                      />
                      <Label className="alert_text">{this.state.dateRangeWarning} </Label>
                    </FormGroup>
                  </Form>
                ) : (<PageSpinner />)}
                <Col>
                  <span className="alert_text mb-1">{this.state.newEventAlert} </span>
                </Col>
              </CardBody>
              <CardFooter className="d-flex justify-content-center">
                <Form >
                  <Button
                    disabled={(!this.state.selectedService.length) || (!this.state.selectedDate) || (!!this.state.dateRangeWarning)}
                    onClick={() => this.nextStep()}
                    color={'secondary'}
                  >
                    <FaForward display="inline-block" className="button-icon" /> Next
                  </Button>
                </Form>
              </CardFooter>
            </Card>
          </div>
        )
      case 2:
        return (
       <div className = "mt-4">
        <Card>
          <CardHeader>
            <FaBookmark size={'1.2em'} color={secondaryColor} className="card-header-icon text-secondary" /> Select Appointment
                  </CardHeader>
          <CardBody>
            {availability_ready ? this.renderAvailabilityBox() : (<PageSpinner />)}
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Form >
              <Button
                onClick={() => this.prevStep()}
                color={'secondary'}
              >
                <FaBackward display="inline-block" className="button-icon" /> Back
                      </Button>
            </Form>
          </CardFooter>
        </Card>
       </div>
      )
      case 3:
        return (
        <div className = "mt-4">
          <Card>
            <CardHeader>
              <FaBookmark size={'1.2em'} color={secondaryColor} className="card-header-icon text-secondary" /> Info
                    </CardHeader>
            <CardBody>
              <Form inline>
                <FormGroup className="mb-2">
                  <Label for="phone_num_input1" sm = {12} md = {4} className="px-0 justify-content-start">Valid Phone Number:</Label>
                  
                  <PhoneCodeSelector 
                    phone_code = {this.state.phone_code}
                    handleInputChange  = {this.handleInputChange}
                    className = "my-2 mr-sm-2"
                  />
                  <Input
                    value={this.state.phone_num}
                    onChange={this.handleInputChange}
                    name="phone_num"
                    id="phone_num_input1"
                    placeholder="Ex: 1234567890"
                    className = "my-2"
                    valid={this.state.phone_num ? validatePhoneNumber(this.state.phone_code + this.state.phone_num) : false}
                    invalid={this.state.phone_num ? !(validatePhoneNumber(this.state.phone_code + this.state.phone_num)) : false}
                  />
                </FormGroup>
              </Form>
              <Form inline>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label for="firstnameinput1" className="mr-2">Name: </Label>
                  <Label for="firstnameinput1" className="mr-sm-2" hidden>First Name</Label>
                  <Input
                    className="mb-2"
                    value={this.state.first_name}
                    onChange={this.handleInputChange}
                    name="first_name"
                    id="firstnameinput1"
                    maxLength={20}                  
                    placeholder="First Name (*)"
                  />
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label for="middlenameinput1" className="mr-sm-2" hidden>Middle Name</Label>
                  <Input
                    className="mb-2"
                    value={this.state.middle_name}
                    onChange={this.handleInputChange}
                    name="middle_name"
                    maxLength={20}                      
                    placeholder="Middle Name"
                    id="middlenameinput1"
                  />
                </FormGroup>
                <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                  <Label for="lastnameinput1" className="mr-sm-2" hidden>Last Name</Label>
                  <Input
                    className="mb-2"
                    value={this.state.last_name}
                    onChange={this.handleInputChange}
                    name="last_name"
                    maxLength={20}                      
                    placeholder="Last Name (*)"
                    id="lastnameinput1"
                  />
                </FormGroup>
              </Form>
              <Form>
                <FormGroup className = "booking-confirm mt-4 ml-3">
                    <Label>
                      <Input type="radio" name="allow_contact" className = "booking-confirm__radio mr-3"
                        onClick = {() => {
                          this.setState({allow_contact: !this.state.allow_contact})
                        }}
                        checked = {this.state.allow_contact}
                      />{' '}
                      <small id="privacyHelp" className="form-text text-muted">
                        We are commited to protecting and respecting your privacy. By checking in, you consent to us sending you text messages and/or email to confirm your appointment, or inform you about the promotions or other services we offer. You can choose to stop receiving anything from us any time.
                      </small>
                    </Label>
                </FormGroup>   
              </Form>
            </CardBody>
            
            <CardFooter className="d-flex justify-content-center">
              <Form >
                <Button
                  onClick={() => this.prevStep()}
                  color={'secondary'}
                >
                  <FaBackward display="inline-block" className="button-icon" /> Back
                        </Button>
                <Button
                  onClick={(event) => this.handleAppointmentSubmit(event)}
                  color={'primary'}
                  className="ml-2"
                  disabled={this.checkSubmit()
                  }
                >
                  <FaCheckDouble display="inline-block" className="button-icon" /> Submit
                        </Button>
              </Form>
            </CardFooter>
          </Card>
        </div>
        
        )
      case 4:
        return (
        <div className = "mt-4">
          <Card>
            <CardBody>
              {new_event_success ? (
                <CardText className="justify-content-center text-center">
                  <FaCheckCircle size={'1.8em'} className="d-block card-header-icon text-success mx-auto" />
                      Thank you! A text message has been sent to you to verify your phone number. Your phone number has to be verified before the request is sent to the store. You will receive another text message when the store confirms your booking.
                </CardText>
              ) : (<PageSpinner />)}
            </CardBody>
            <CardFooter className="d-flex justify-content-center">
              <Form >
                <Button
                  onClick={() => this.cleaninput()}
                  color={'primary'}
                  className="ml-2"
                >
                  <FaPlusSquare display="inline-block" className="button-icon" /> Make Another Appointment
                        </Button>
              </Form>
            </CardFooter>
          </Card>
        </div>

        )
      default:
        break;
    }
  }

  render() {
    const { step, lastStoreId } = this.state

    return (
      <Page
        title="Booking"
        className="mt-3"
      >
        <div className="mx-auto booking-page-element">
            {this.renderStoreInfo()}
            {this.renderStepProgressBar(step)}
            {this.renderStepSwitch(step)}
        </div>     
      </Page>
    );
  }
}
const mapStateToProps = state => {
  return {
    service: state.serviceList,
    userState: state.userData,
    currentStoreState: state.currentStore,
    allStoreState: state.allStoreList,
    garellyImages: state.garellyImages

    // fullStoreInfo:state.fullStoreInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onfetchAvailableService: (data) => dispatch(fetchAvailableService(data)),
    fetchGarellyImages: (data) => dispatch(fetchGarellyImages(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Waitlisttotal);