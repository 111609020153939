import React from 'react';

import bn from '../../utils/bemnames';

import { Container } from 'reactstrap';

const bem = bn.create('content');

const Content = ({ tag: Tag, className, ...restProps }) => {

  // Check page is employee and online booking
  // const checkPage = () => {
  //   const pathname = window.location.pathname;

  //   if (pathname.indexOf("staffreport") >= 0) {
  //     return false;
  //   }
    
  //   return pathname.indexOf("staff") >= 0 ||
  //         pathname.indexOf("booking") >= 0
  // }

  const classes = bem.b(className);

  return <Tag 
    className={classes} 
    {...restProps}
    // style = {{
    //   minWidth: checkPage() ? '100%' : '720px'
    // }} 
  />;
};

Content.defaultProps = {
  tag: Container,
};

export default Content;
