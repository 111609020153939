import React, { Component } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { connect } from 'react-redux';
import API from '../utils/API';
import { Label } from '../components/Form';

var isRequest = false;
class Unsubscribe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            message: 'Unsubscribe Successfull!!'
        }
    }


    componentDidMount() {
        if (!isRequest) {
            isRequest = true;
            let id = this.findGetParameter("id");
            this.unsubscribe(id);
        }

    }

    findGetParameter(parameterName) {
        var result = null,
            tmp = [];
        var items = window.location.search.substr(1).split("&");
        for (var index = 0; index < items.length; index++) {
            tmp = items[index].split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        }
        return result;
    }

    unsubscribe = (id) => {
        API.unsubscribleSMS(id)
            .then(result => {
                if (result.status === 200 && result.data.n > 0) {
                    console.log('vao 1');
                } else {
                    console.log('vao 2');
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    render() {
        const { message } = this.state;
        return (
            <Row
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '99vw'
                }}>
                <Col md={6} lg={4}>
                    <Card body>
                        <Label>{message}</Label>
                    </Card>
                </Col>

            </Row>
        );
    }
}


export default connect()(Unsubscribe);
