
import sidebarBgImage from '../../assets/img/sidebar/sidebar-4.jpg';
import logo200ImageFlat from '../../assets/img/logo/logo_200_flat.png';
import React from 'react';
import {
  MdAccountCircle,
  MdDashboard,
  MdAspectRatio,
  MdAssignment,
  MdTextsms,
  MdSettings,
  MdLocalOffer,
  MdStore,
  MdCheckCircle,
  MdToday,
  MdLink,
  MdGroup
} from 'react-icons/md';
import {
  FaSignOutAlt,
  FaSignInAlt
} from 'react-icons/fa';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  // UncontrolledTooltip,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
  Badge
} from 'reactstrap';
import bn from '../../utils/bemnames';
import { setCurrenBarIndex, setUserData, setSideBarClick } from '../../store/actions/action';

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};


const bem = bn.create('sidebar');
const CONSTANT = require('../../utils/Const');

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: []
    };
  }

  componentDidMount() {
    let tabIndex = localStorage.getItem(CONSTANT.key.TABINDEX);
    let index = 0;
    if (tabIndex) index = parseInt(tabIndex);
    this.props.setCurrenBarIndex(index);
  }

  checkRole(_role, acc_owner) {
    let currentStoreState = '';
    let currentStoreData = localStorage.getItem(CONSTANT.key.STORE);
    if (currentStoreData && currentStoreData.length > 0) {
      currentStoreState = JSON.parse(currentStoreData);
    } else {
      currentStoreState = this.props.currentStoreState;
    }

    const { currentStore } = currentStoreState;
    if (!currentStore){
      this.signOut();
      window.location = CONSTANT.baseUrl;
    }
    const currentBranchName = currentStore.branchName;

    let route = localStorage.getItem(CONSTANT.key.ROUTE);
    let role = "";

    if (route && route.length > 0) {
      role = this.props.currentRoleState;
    } else {
      role = _role;
    }

    switch (role) {
      case 'employee':
        const navItemsEmployee = [
          { to: '/' + currentBranchName + '/staff/dashboard', name: 'active customers', exact: true, Icon: MdDashboard },
          { to: '/' + currentBranchName + '/staff/calendar', name: 'calendar', exact: false, Icon: MdToday },
          { to: '/' + currentBranchName + '/staff/appointmentsetting', name: 'Appointment Setting', exact: false, Icon: MdSettings },
        ];
        console.log('navItemsEmployee=====', navItemsEmployee);
        return navItemsEmployee;
      case 'front':
        const navItemsFront = [
          { to: '/' + currentBranchName + '/front', name: 'dashboard', exact: true, Icon: MdDashboard },
          { to: '/' + currentBranchName + '/front/checkin', name: 'checkin', exact: false, Icon: FaSignInAlt },
          { to: '/' + currentBranchName + '/front/complete', name: 'complete', exact: false, Icon: MdCheckCircle },
          { to: '/' + currentBranchName + '/front/calendar', name: 'calendar', exact: false, Icon: MdToday },
          { to: '/' + currentBranchName + '/accesslink', name: 'Access link', exact: false, Icon: MdLink },                  
          // { to: '/front/setting', name: 'Account & Setting', exact: false, Icon: MdSettings },
        ];
        console.log('navItemsFront=====', navItemsFront);
        return navItemsFront;
      case 'manager':
        if (acc_owner) {
          const navItemsOwner = [
            { to: '/' + currentBranchName + '/manager', name: 'dashboard', exact: true, Icon: MdDashboard },
            { to: '/' + currentBranchName + '/manager/custandgroup', name: 'customer & group', exact: false, Icon: MdAccountCircle },
            { to: '/' + currentBranchName + '/manager/promotion', name: 'promotion', exact: false, Icon: MdLocalOffer },
            { to: '/' + currentBranchName + '/manager/storesetting', name: 'store setting', exact: false, Icon: MdStore },
            { to: '/' + currentBranchName + '/manager/storereport', name: 'store report', exact: false, Icon: MdAssignment },
            { to: '/' + currentBranchName + '/manager/staffreport', name: 'staff report', exact: false, Icon: MdAssignment },
            { to: '/' + currentBranchName + '/manager/complete', name: 'complete visit', exact: false, Icon: MdCheckCircle },
            { to: '/' + currentBranchName + '/manager/calendar', name: 'calendar', exact: false, Icon: MdToday },
            { to: '/' + currentBranchName + '/manager/sms', name: 'Messaging', exact: false, Icon: MdTextsms },
            { to: '/' + currentBranchName + '/accesslink', name: 'Access link', exact: false, Icon: MdLink },            
            { to: '/' + currentBranchName + '/manager/setting', name: 'Account & Setting', exact: false, Icon: MdSettings },
          ];
          console.log('navItemsOwner=========', navItemsOwner);
          return navItemsOwner;
        } else {
          const navItemsManager = [
            { to: '/' + currentBranchName + '/manager', name: 'dashboard', exact: true, Icon: MdDashboard },
            { to: '/' + currentBranchName + '/manager/custandgroup', name: 'customer & group', exact: false, Icon: MdAccountCircle },
            { to: '/' + currentBranchName + '/manager/promotion', name: 'promotion', exact: false, Icon: MdLocalOffer },
            { to: '/' + currentBranchName + '/manager/storesetting', name: 'store setting', exact: false, Icon: MdAspectRatio },
            { to: '/' + currentBranchName + '/manager/storereport', name: 'store report', exact: false, Icon: MdAssignment },
            { to: '/' + currentBranchName + '/manager/staffreport', name: 'employee report', exact: false, Icon: MdAssignment },
            { to: '/' + currentBranchName + '/manager/complete', name: 'complete visit', exact: false, Icon: MdCheckCircle },
            { to: '/' + currentBranchName + '/manager/calendar', name: 'calendar', exact: false, Icon: MdToday },
            { to: '/' + currentBranchName + '/manager/sms', name: 'Messaging', exact: false, Icon: MdTextsms },
            { to: '/' + currentBranchName + '/accesslink', name: 'Access link', exact: false, Icon: MdLink },                  
            { to: '/' + currentBranchName + '/manager/setting', name: 'Account & Setting', exact: false, Icon: MdSettings },
          ];
          console.log('navItemsManager=====', navItemsManager);
          return navItemsManager;
        }
      case 'customer':
        const navItemsCustomer = [
          // { to: '/' + currentBranchName }
        ];
        console.log('navItemsCustomer=====', navItemsCustomer);
        return navItemsCustomer;
      default:
        const emptyNav = [
        ];
        console.log('navItemsCustomer=====', navItemsCustomer);
        return emptyNav;
    }
  }

  _onClickNavBar(index, to) {
    this.props.setSideBarClick(true);
    localStorage.setItem(CONSTANT.key.ROUTE, to);
    this.props.setCurrenBarIndex(index);
    localStorage.setItem(CONSTANT.key.TABINDEX, index);
  }

  signOut() {

    localStorage.setItem(CONSTANT.key.USER, '');
    localStorage.setItem(CONSTANT.key.ROUTE, '');
    localStorage.setItem(CONSTANT.key.STORE, '');
    localStorage.setItem(CONSTANT.key.TABINDEX, 0);
    this.props.setUserData('');
    // this.props.onLogout();
  }

  getLogoutLink = () => {
    const {isEmployee} = this.props.userState || false;
    const currentStore = this.props.currentStoreState.currentStore;

    if (isEmployee) {
      return `/${currentStore.branchName}/staff`;
    } else  {
      return "/"
    }
  }

  renderNavBar = (role, acc_owner) => {
    const {pendingAppointmentList} = this.props;
    const { username } = this.props.userState;

    let navBarData = this.checkRole(role, acc_owner);
    if (username === "owner1@gmail.com") {
      navBarData.push( { to:  '/manager/accounts', name: 'Users', exact: false, Icon: MdGroup } );
    }

    return (
      <div>
        {navBarData.map(({ to, name, exact, Icon }, index) => {
          return (
            <NavItem key={index} className={bem.e('nav-item')}>
              <BSNavLink
                id={`navItem-${name}-${index}`}
                className={this.props.currentBarIndex === index ? "text-uppercase active" : "text-uppercase"}
                tag={NavLink}
                to={to}
                activeClassName=""
                exact={!exact}
                onClick={() => this._onClickNavBar(index, to)}
              >
                <Icon className={bem.e('nav-item-icon')} />
                <span className="">{name}</span>
                { pendingAppointmentList.length > 0  && name == "calendar" &&
                  <Badge className = "p-2 ml-3">{pendingAppointmentList.length}</Badge>
                }
              </BSNavLink>
            </NavItem>
          )
        })}
        <NavItem className={bem.e('nav-item')}>
          <BSNavLink
            id={`nav_signout`}
            className="text-uppercase"
            tag={NavLink}
            to={this.getLogoutLink()}
            activeClassName=""
            // exact={!exact}
            onClick={() => this.signOut()}
          >
            <FaSignOutAlt className={bem.e('nav-item-icon')} />
            <span className="">Sign out</span>
          </BSNavLink>
        </NavItem>
      </div>
    )
  }

  render() {
    const role = this.props.currentRoleState;
    const { acc_owner } = this.props.userState;

    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e('background')} style={sidebarBackground} />
        <div className={bem.e('content')}>
          <Navbar>
            <img
              src={logo200ImageFlat}
              style={{ width: '90%', marginTop: '1em'}}
              alt="logo"
            />
          </Navbar>
          <Nav vertical>
            {this.renderNavBar(role, acc_owner)}
          </Nav>
        </div>
      </aside>
    );
  }
}

function mapStateToProps(state) {
  return {
    allStoreState: state.allStoreList,
    currentStoreState: state.currentStore,
    userState: state.userData,
    currentRoleState: state.currentRole,
    currentBarIndex: state.currentBarIndex,
    pendingAppointmentList: state.pendingAppointmentList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrenBarIndex: (data) => dispatch(setCurrenBarIndex(data)),
    setUserData: (data) => dispatch(setUserData(data)),
    setSideBarClick: (data) => dispatch(setSideBarClick(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
